import { AdminLogin } from "../components/AdminLogin"
import { Dashboard } from "../components/Dashboard"
import { GoogleUserRegister } from "../components/GoogleUserRegister"
import { Home } from "../components/Home"
import { Login } from "../components/Login"
import { PlayersCRUD } from "../components/cruds/PlayersCRUD"
import { TournamentsCRUD } from "../components/cruds/TournamentsCRUD"
import { ILoaderClass } from "./LoaderClass"

export class Router {
    public static readonly PATH_PARAM = ":id"

    public static readonly LOGIN_URL = "login"
    public static readonly TOURNAMENTS_URL = "tournaments"
    public static readonly PLAYERS_URL = "players"
    public static readonly GOOGLE_REGISTER_URL = "google-register"
    public static readonly ADMIN_LOGIN = "admin-login"
    public static readonly LOCALS = "locals"
    public static readonly REGIONALS = "regionals"

    public static polishUrl = (url: string) => {
        const baseUrl = url.endsWith("/") ? url : url + "/"

        return baseUrl.split("#")[0]
    }
    
    public static getBaseUrl = () => this.polishUrl(process.env.PUBLIC_URL)

    private static readonly PAGES = new Map<string | string[], ILoaderClass<unknown, unknown>>([
        [this.getBaseUrl(), Dashboard],
        [this.getBaseUrl() + this.LOCALS, Home],
        [this.getBaseUrl() + this.REGIONALS, Home],
        [this.getBaseUrl() + this.LOCALS + "/" + this.PATH_PARAM, Home],
        [this.getBaseUrl() + this.REGIONALS + "/" + this.PATH_PARAM, Home],
        [this.getBaseUrl() + this.LOGIN_URL, Login],
        [this.getBaseUrl() + this.TOURNAMENTS_URL, TournamentsCRUD],
        [this.getBaseUrl() + this.PLAYERS_URL, PlayersCRUD],
        [this.getBaseUrl() + this.GOOGLE_REGISTER_URL, GoogleUserRegister],
        [this.getBaseUrl() + this.ADMIN_LOGIN, AdminLogin]
    ])

    public static getPage = (url: string) => {
        let page: ILoaderClass<any, any>
        let urlToGet = url

        page = this.PAGES.get(urlToGet)

        if (!page) {
            const pieces = url.split("/"),
            rebuildUrl = pieces.filter((_, i) => i < pieces.length - 1).join("/")
            urlToGet = rebuildUrl + "/" + this.PATH_PARAM
        }

        page = this.PAGES.get(urlToGet)

        if (!page) {
            Array.from(this.PAGES.keys()).forEach(key => {
                if(Array.isArray(key) && (key.includes(urlToGet) || key.includes(url)))
                    page = this.PAGES.get(key)
            })
        }

        if(!page)
            return null

        return page
    }

    public static getPathParams = () => {
        const url = window.location.pathname,
        pieces = url.split("/")

        if (this.PAGES.has(this.getWindowUrl()) || Array.from(this.PAGES.keys()).some(k => Array.isArray(k) && k.includes(url)))
            return {}

        return { [this.PATH_PARAM.replace(":", "")]: pieces[pieces.length - 1] }
    }

    public static getWindowUrl = () => this.polishUrl((window.location.pathname.substring(this.getBaseUrl().length, window.location.pathname.length)) || "")

    public static isUrl = (url: string) => {
        const polished = url === this.getBaseUrl() ? url : this.getBaseUrl() + url
        return this.polishUrl(window.location.pathname) === polished || window.location.pathname === polished
    }

    public static navigate = (url = Router.getBaseUrl(), params = {}) => {
        if(Router.polishUrl(url) !== Router.polishUrl(Router.getWindowUrl())){
            const polishUrl = url === Router.getBaseUrl() ? Router.getBaseUrl() : Router.getBaseUrl() + url

            window.history.pushState(params, "", polishUrl)
        }
    }

    public static showNavbar = () => !this.isUrl(this.LOGIN_URL) && !this.isUrl(this.GOOGLE_REGISTER_URL) && !this.isUrl(this.ADMIN_LOGIN)
}