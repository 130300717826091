import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, Form, FormButton, FormCombobox, FormDatepicker, FormGroup, FormInput, Option, Tooltip } from "dolfo2"
import { GPlayerPronoun, IGPlayer } from "pkm-special-tool"
import React from "react"
import { AuthService } from "../apis/AuthService"
import { makeApiCall } from "../apis/ServerCall"
import { GUserInfos } from "../interfaces/GUserInfos"
import { IContext } from "../interfaces/IContext"
import { CustomStorage } from "../utils/CustomStorage"
import { translate } from "../utils/LangUtils"
import { LoaderClass } from "../utils/LoaderClass"
import { Router } from "../utils/Router"
import { Footer } from "./Footer"
import { AppContext } from "./Root"

export class GoogleUserRegister extends LoaderClass<GUserInfos>{
    form = new FormGroup<Omit<IGPlayer, "id">>({
        email: { value: this.props.email, required: true },
        first_name: { value: this.props.given_name, required: true },
        last_name: { value: this.props.family_name, required: true },
        birth_date: { value: null, required: true },
        player_id: { value: null, required: true },
        pronoun: { value: null, required: true },
        google_id: { value: this.props.sub }
    })

    isAuthorized = () => this.props && !!this.props.email && !CustomStorage.has(CustomStorage.STORAGE_TOKEN)

    submit = (login: IContext["login"]) => Dialog.confirm(
        translate("warning"),
        translate("googleRegisterInfo"),
        () => {
            this.toggleLoading()
    
            makeApiCall({
                promise: AuthService.registerWithGoogle(this.form.getObjectValue()).then(token => {
                    CustomStorage.set(CustomStorage.STORAGE_TOKEN, token)
                    return AuthService.getSession()
                }),
                onSuccess: user => login(user),
                doFinally: () => this.toggleLoading()
            })

            return true
        }
    )

    render = () => {
        const { loading } = this.state
        
        return <div className="login-container">
           <div className="login-box pm-card mb-3">
                <h2>
                    <Tooltip label={translate("back")}>
                        <Button shape="pill" color="white" onClick={() => Router.navigate(Router.LOGIN_URL)} disabled={loading} className="me-1">
                            <FontAwesomeIcon icon={faChevronLeft} type="far" />
                        </Button>
                    </Tooltip>
                    {translate("register")}
                </h2>

                <AppContext.Consumer>
                    {
                        ({ login }) => <Form frm={this.form} onSubmit={() => this.submit(login)}>
                            <FormInput controlName="email" label="E-mail" disabled maxLength={200} />
                            <FormInput controlName="first_name" maxLength={100} label={translate("players.firstName")} disabled={loading} />
                            <FormInput controlName="last_name" maxLength={100} label={translate("players.lastName")} disabled={loading} />
                            <FormDatepicker controlName="birth_date" label={translate("players.birthDate")} disabled={loading} />
                            <FormInput controlName="player_id" type="number" steps={1} max={9999999} label={translate("players.gUserId")} disabled={loading} />
                            <FormCombobox controlName="pronoun" label={translate("players.pronoun")} disabled={loading}>
                                {
                                    Object.values(GPlayerPronoun).map(key => <Option key={key} value={key} label={key} />)
                                }
                            </FormCombobox>

                            <FormButton loading={loading} color="dark" size="full">
                                {translate("registerEnd")}
                            </FormButton>
                        </Form>
                    }
                </AppContext.Consumer>
            </div>

            <Footer lightText />
        </div>
    }
}