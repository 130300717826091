import { faCheckCircle, faEnvelopeCircleCheck, faEye, faEyeSlash, faFileExport, faUserPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, FormCheckbox, FormCombobox, FormControlsObject, FormDatepicker, FormGroup, FormInput, FormRadio, FormTimepicker, Option, Tab, Tabs, Tooltip } from "dolfo2"
import { XMLBuilder } from "fast-xml-parser"
import moment from "moment"
import { IApiGPlayer, IApiUser, INation, IPlayer, ITournamentApi, ITournamentTick, InternalType, RolesPermissionsType, SubscriptionType } from "pkm-special-tool"
import React from "react"
import { ServerCall, makeApiCall, showMessage } from "../../apis/ServerCall"
import { EColumn } from "../../interfaces/IUtils"
import { EntityCRUD, SaveType } from "../../utils/EntityCRUD"
import { getCurrentLanguage, translate } from "../../utils/LangUtils"
import { EmailWriter } from "../players/EmailWriter"
import { PlayersForm } from "../players/PlayersForm"
import { PlayersSearch } from "../players/PlayersSearch"
import { SUBSCRIPTIONS_URL, SubscribedPlayers } from "../players/SubscribedPlayers"

interface IState{
    readonly showState: boolean
    readonly ticks: ITournamentTick[]
}

export const TOURNAMENT_API_URL = "tournaments"

export class TournamentsCRUD extends EntityCRUD<ITournamentApi, unknown, IState>{
    public apiURL = TOURNAMENT_API_URL
    private ORGANIZER_ALLOWED_KEYS: (keyof ITournamentApi)[] = [
        "prize_tix_per_win",
        "prize_tix_for_participation",
        "description",
        "price",
        "start_time",
        "internal_type",
        "type_custom_label"
    ]

    public isAuthorized = (user?: IApiUser & IApiGPlayer) => user?.permissions?.[RolesPermissionsType.MANAGE_TOURNAMENTS] || !user?.isGoogle

    protected isOrganizer = (item = this.state.flag) => this.context.getUser()?.organizer_name === item?.organizer_name

    protected buildForm = (item: ITournamentApi) => ({
        tournament_id: {
            value: item.tournament_id,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.tournamentId"),
                maxLength: 200
            })
        },
        type: {
            value: item.type,
            required: true,
            render: () => this.buildParamComponent(FormCombobox, {
                label: translate("tournaments.type"),
                children: this.context.getTournamentTypes().map(n => React.createElement(Option, {
                    label: n.name,
                    value: n.id
                }))
            })
        },
        type_custom_label: {
            value: item.type_custom_label,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.typeLabel"),
                maxLength: 20
            })
        },
        internal_type: {
            value: item.internal_type || InternalType.LOCALS,
            required: true,
            render: () => this.buildParamComponent(FormRadio, {
                label: translate("tournaments.category.label"),
                children: [
                    React.createElement(Option, {
                        key: InternalType.LOCALS,
                        label: translate("tournaments.category.locals"),
                        value: InternalType.LOCALS
                    }),
                    React.createElement(Option, {
                        key: InternalType.REGIONALS,
                        label: translate("tournaments.category.regionals"),
                        value: InternalType.REGIONALS
                    })
                ]
            })
        },
        name: {
            required: true,
            value: item.name,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.name"),
                maxLength: 100
            })
        },
        description: {
            required: true,
            value: item.description,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.description"),
                type: "textarea",
                rows: 5
            })
        },
        date: {
            required: true,
            value: item.date,
            render: () => this.buildParamComponent(FormDatepicker, {
                label: translate("tournaments.date"),
                dateFormat: translate("dateFormat")
            })
        },
        start_time: {
            required: true,
            value: item.start_time,
            render: () => this.buildParamComponent(FormTimepicker, {
                label: translate("tournaments.startTime"),
                timeFormat: translate("timeFormat")
            })
        },
        town: {
            required: true,
            value: item.town,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.town"),
                maxLength: 200
            })
        },
        organizer_id: {
            required: true,
            value: item.organizer_id,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.organizer_id"),
                max: 9999999,
                decimals: 0,
                type: "number",
                min: 0,
                steps: 1
            })
        },
        organizer_name: {
            required: true,
            value: item.organizer_name,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.organizer_name"),
                maxLength: 200
            })
        },
        nation_id: {
            required: true,
            value: item.nation_id,
            render: () => this.buildParamComponent(FormCombobox, {
                label: translate("tournaments.nation"),
                children: this.context.getNations().map(n => React.createElement(Option, {
                    label: n["name_" + getCurrentLanguage() as keyof INation],
                    value: n.id
                })),
                onChange: n => {
                    const nation = this.context.getNations().find(nation => nation.id === n)

                    this.builtForm = this.buildForm(this.form.getObjectValue())
                        
                    this.form = new FormGroup<ITournamentApi>(this.builtForm as FormControlsObject<ITournamentApi>)
                    
                    this.setInternalState({ showState: nation?.isUsa }, "Show nation")
                }
            })
        },
        state: {
            value: item.state,
            required: this.state.showState,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.state"),
                maxLength: 200
            })
        },
        price: {
            value: item.price,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.price"),
                type: "number",
                min: 0,
                max: 99
            })
        },
        prize_tix_for_participation: {
            value: item.prize_tix_for_participation,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.prize_tix_for_participation"),
                type: "number",
                min: 0
            })
        },
        prize_tix_per_win: {
            value: item.prize_tix_per_win,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.prize_tix_per_win"),
                type: "number",
                min: 0
            })
        },
        subscription_type: {
            value: item.subscription_type || SubscriptionType.MANUAL,
            required: true,
            render: () => this.buildParamComponent(FormRadio, {
                label: translate("tournaments.subscriptionType"),
                children: [
                    React.createElement(Option, {
                        key: SubscriptionType.MANUAL,
                        label: translate("tournaments.subscriptionManual"),
                        value: SubscriptionType.MANUAL
                    }),
                    React.createElement(Option, {
                        key: SubscriptionType.GOOGLE_ACCOUNT,
                        label: translate("tournaments.subscriptionGoogle"),
                        value: SubscriptionType.GOOGLE_ACCOUNT
                    })
                ]
            })
        },
        max_subscriptions: {
            value: item.max_subscriptions,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("tournaments.max_subscriptions"),
                type: "number",
                min: 1,
                decimals: 0,
                steps: 1
            })
        },
        require_players_confirmation: {
            value: item.require_players_confirmation || false,
            render: () => this.buildParamComponent(FormCheckbox, {
                label: translate("tournaments.requireConfirmation")
            })
        }
    }) as any

    protected getBaseColumns = (): EColumn<ITournamentApi>[] => [
        {
            label: translate("tournaments.name"),
            order: { key: "name" },
            filter: { key: "name" },
            extract: d => d.name,
            width: 150
        },
        {
            label: translate("tournaments.date"),
            order: { key: "date", type: "date" },
            filter: { key: "date", type: "date" },
            align: "center",
            width: 100,
            extract: d => {
                let date = moment(d.date).format(translate("dateFormat"))

                if(d.start_time)
                    date += " " + moment(d.start_time).format(translate("timeFormat"))

                return date
            }
        }
    ]

    public getColumns = (): EColumn<ITournamentApi>[] => {
        const cols = [...this.getBaseColumns()]
        cols.splice(1, 0, {
            label: translate("tournaments.players_count"),
            order: { key: "name", type: "number" },
            extract: d => d.playersCount,
            width: 50,
            align: "right"
        })

        return cols
    }

    public getData = (items: ITournamentApi[]) => items?.filter(r => !r.hidden || (this.hasPermission(RolesPermissionsType.HIDE_TOURNAMENTS) && this.canSave(SaveType.UPDATE, null)))
        .map(i => ({
        ...i,
        date: new Date(i.date),
        start_time: i.start_time ? new Date(i.start_time) : null
    }))

    protected canDelete = () => this.hasPermission(RolesPermissionsType.DELETE_TOURNAMENTS)

    protected canSave = (_: SaveType, item: ITournamentApi) => this.hasPermission(RolesPermissionsType.MANAGE_TOURNAMENTS) || this.isOrganizer(item)

    protected onOpenDetail = (item: ITournamentApi) => {
        if(this.isOrganizer(item) && !this.isNew(item)){
            Object.keys(this.form.getObjectValue()).filter(k => !this.ORGANIZER_ALLOWED_KEYS.includes(k as keyof ITournamentApi))
                .forEach(k => this.form.disable(k as keyof ITournamentApi))
        }
    }

    protected getToolbarActions = () => {
        if(this.isNew())
            return React.createElement(React.Fragment)

        const { loading } = this.state,
        children: React.ReactNode[] = []

        if(this.hasPermission(RolesPermissionsType.MANAGE_TOURNAMENTS) || this.isOrganizer()){
            children.push(React.createElement(Tooltip, {
                label: translate("tournaments.exportTDF"),
                children: React.createElement(Button, {
                    color: "blue2",
                    shape: "text",
                    disabled: loading,
                    onClick: this.downloadTDF,
                    children: React.createElement(FontAwesomeIcon, {
                        icon: faFileExport,
                        fixedWidth: true,
                        className: "h3"
                    })
                })
            }))
        }

        if(this.hasPermission(RolesPermissionsType.MANAGE_PLAYERS) || this.isOrganizer()){
            children.push(React.createElement(Tooltip, {
                label: translate("tournaments.managePlayers"),
                children: React.createElement(Button, {
                    color: "orange",
                    shape: "text",
                    disabled: loading,
                    onClick: this.openTournamentPlayers,
                    children: React.createElement(FontAwesomeIcon, {
                        icon: faUserPlus,
                        fixedWidth: true,
                        className: "h3"
                    })
                })
            }))
        }

        if(this.hasPermission(RolesPermissionsType.WRITE_EMAIL) || this.isOrganizer()){
            children.push(React.createElement(Tooltip, {
                label: translate("tournaments.writeMail"),
                children: React.createElement(Button, {
                    color: "blue",
                    shape: "text",
                    disabled: loading,
                    onClick: this.writeEmail,
                    children: React.createElement(FontAwesomeIcon, {
                        icon: faEnvelopeCircleCheck,
                        fixedWidth: true,
                        className: "h3"
                    })
                })
            }))
        }
        
        return React.createElement(React.Fragment, { children })
    }

    private writeEmail = () => Dialog.openComponent(EmailWriter, { id: this.state.flag.id })

    private openTournamentPlayers = () => {
        const { id, require_players_confirmation } = this.state.flag

        this.toggleDisableEscape()
        
        Dialog.open({
            title: translate("tournaments.addPlayer"),
            className: "top-dialog",
            children: React.createElement(Tabs, {
                children: [
                    React.createElement(Tab, {
                        label: translate("tournaments.createPlayer"),
                        children: React.createElement(PlayersForm, { id })
                    }),
                    React.createElement(Tab, {
                        label: translate("tournaments.searchPlayer"),
                        children: React.createElement(PlayersSearch, { id })
                    }),
                    React.createElement(Tab, {
                        label: translate("tournaments.subscribed"),
                        children: React.createElement(SubscribedPlayers, {
                            id,
                            showChecks: require_players_confirmation
                        })
                    })
                ]
            }),
            hideFooter: true,
            onCancel: () => Promise.resolve(this.toggleDisableEscape()).then(() => true)
        })
    }

    private downloadTDF = () => {
        const dialog = EntityCRUD.openLoadingDialog(),
        { id, type, name, tournament_id, town, state, nation_id, date, organizer_id, organizer_name } = this.state.flag,
        tourType = this.context.getTournamentTypes().find(t => t.id === type),
        nation = this.context.getNations().find(n => n.id === nation_id)

        makeApiCall({
            promise:  Promise.all([
                ServerCall.get<IPlayer[]>(SUBSCRIPTIONS_URL + "/" + id),
                ServerCall.get<boolean>(SUBSCRIPTIONS_URL + "/all-checked?tournament_id=" + id)
            ]),
            onSuccess: ([players, allChecked]) => {
                if(!allChecked)
                    return showMessage(translate("tournaments.notAllChecked"))
                
                const builder = new XMLBuilder({
                    attributeNamePrefix: "_",
                    ignoreAttributes: false,
                    format: true,
                    indentBy: "	",
                    oneListGroup: true
                }),
                build = builder.build({
                    tournament: {
                        _type: 2,
                        _stage: 1,
                        _version: 1.74,
                        _gametype: tourType.game_type,
                        _mode: tourType.mode,
                        data: {
                            name,
                            id: tournament_id || "",
                            city: town,
                            state: state || "",
                            country: nation.name_en,
                            roundtime: 0,
                            organizer: {
                                _popid: organizer_id,
                                _name: organizer_name
                            },
                            startdate: moment(new Date(date)).format("MM/DD/yyyy"),
                            finalsroundtime: 0,
                            lessswiss: false,
                            autotablenumber: true,
                            overflowtablestart: 0
                        },
                        timeelapsed: 0,
                        players: players.map(p => ({
                            player: {
                                _userid: p.user_id,
                                firstname: p.firstName,
                                lastname: p.lastName,
                                birthdate: moment(new Date(p.birthDate)).format("MM/DD/yyyy"),
                                creationdate: moment(new Date(p.creationDate)).format("MM/DD/yyyy HH:mm:ss"),
                                lastmodifieddate: moment(new Date(p.creationDate)).format("MM/DD/yyyy HH:mm:ss")
                            }
                        })),
                        pods: [],
                        finalsoptions: []
                    }
                }),
                filename = id + "_" + name + ".tdf",
                anchor = document.createElement("a"),
                bb = new Blob(["<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" + build], {
                    type: "text/plain"
                })

                anchor.setAttribute("href", window.URL.createObjectURL(bb))
                anchor.setAttribute("download", filename)

                anchor.dataset.downloadurl = ["text/plain", anchor.download, anchor.href].join(":");

                anchor.click()
            },
            doFinally: dialog.close
        })
    }
    
    protected getActions = (item: ITournamentApi) => {
        if(this.hasPermission(RolesPermissionsType.HIDE_TOURNAMENTS)){
            const { hidden } = item,
            { loading } = this.state

            return React.createElement(Tooltip, {
                label: translate(hidden ? "tournaments.show" : "tournaments.hide"),
                children: React.createElement(Button, {
                    shape: "text",
                    color: hidden ? "green" : "yellow",
                    disabled: loading,
                    children: React.createElement(FontAwesomeIcon, {
                        icon: hidden ? faEye : faEyeSlash
                    }),
                    onClick: () => this.toggleHideItem(item.id, !hidden)
                })
            })
        }
            
        return React.createElement(React.Fragment)
    }

    private toggleHideItem = (id: string, hidden: boolean) => Dialog.confirm(
        translate("warning"),
        hidden ? translate("tournaments.confirmHide") : translate("tournaments.confirmShow"),
        () => {
            this.closeDetail()
            this.toggleLoading()

            makeApiCall({
                promise: ServerCall.put(this.apiURL + "/" + id + "/toggle-hidden", { hidden }),
                doFinally: this.toggleLoading,
                onSuccess: () => {
                    this.setInternalState({
                        list: this.state.list.map(i => i.id === id ? { ...i, hidden } : i)
                    }, "Toggled hidden state")
                    showMessage(translate("tournaments.visibilityChanged"), faCheckCircle, "green")
                }
            })

            return true
        }
    )
}