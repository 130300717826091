import { EventManager } from "dolfo2"
import _ from "lodash"
import { IApiGPlayer, IApiUser, INation, ITournamentType } from "pkm-special-tool"
import React, { createContext } from "react"
import { AuthService } from "../apis/AuthService"
import { BaseCrudAPI } from "../apis/BaseCrudAPI"
import { makeApiCall, ServerCall } from "../apis/ServerCall"
import { IContext, RootContextState } from "../interfaces/IContext"
import { CanLoad } from "../utils/CanLoad"
import { CustomStorage } from "../utils/CustomStorage"
import { EntityCRUD } from "../utils/EntityCRUD"
import { getCurrentLanguage, translate } from "../utils/LangUtils"
import { LoaderClassFn, retrieveAndInstance } from "../utils/LoaderClass"
import { Router } from "../utils/Router"
import { Footer } from "./Footer"
import { Home } from "./Home"
import { Navbar } from "./Navbar"
import { TOURNAMENT_API_URL } from "./cruds/TournamentsCRUD"

export const AppContext = createContext<IContext>(null)

export class Root extends CanLoad<unknown, RootContextState>{
    private id: number
    private event: EventManager
    private langObservers: (() => void)[] = []
    private logoutObservers: (() => void)[] = []

    constructor(props: unknown){
        super(props, {
            instance: null,
            user: null,
            language: getCurrentLanguage(),
            nations: [],
            tournament_types: []
        })

        const pushState = window.history.pushState

        window.history.pushState = (...args) => {
            pushState.apply(window.history, args)
            this.setFromWindow(args[0])
        }
    }

    componentDidMount = () => {
        this.restartDolfoConfig()
        this.event = new EventManager("popstate", this.setFromWindow).register()

        this.toggleLoading()

        makeApiCall({
            promise: Promise.all([
                new BaseCrudAPI<INation>("nations").getList(),
                ServerCall.get<ITournamentType[]>(TOURNAMENT_API_URL + "/types")
            ]).then(([nations, tournament_types]) => {
                this.setInternalState({
                    nations,
                    tournament_types
                }, "Loaded nations and types")

                if(CustomStorage.has(CustomStorage.STORAGE_TOKEN)){
                    return makeApiCall({
                        promise: AuthService.getSession(),
                        onError: err => {
                            if(err.response?.status === 401)
                                this.logout()
        
                            return null
                        }
                    })
                }

                return null
            }),
            onSuccess: user => this.setInternalState({ user, loading: false }, "Loaded user", this.setFromWindow)
        })
    }

    componentWillUnmount = () => this.event.unregister()

    setFromWindow = (params = {}) => {
        const component = Router.getPage(
            Router.getBaseUrl() + Router.getWindowUrl().substring(0, Router.getWindowUrl().length - 1)
        )

        this.loadComponent(component, params)
    }

    loadComponent: LoaderClassFn = (type, params) => {
        const id = this.id = Number(_.uniqueId())

        if(!type)
            return this.setState({ instance: null })

        const instance = new type(params)

        if(!instance.isAuthorized(this.state.user))
            return Router.navigate(Router.getBaseUrl())

        retrieveAndInstance(type, loading => {
            if(id === this.id)
                this.setInternalState({ loading }, "Toggled loading")
        }, params).then(result => {
            if(this.id !== id)
                return

            this.setState({ instance: result, loading: false })
        })
    }

    logout = () => {
        this.setInternalState({ user: null }, "Logged out", () => {
            if(Router.isUrl(Router.TOURNAMENTS_URL) || Router.isUrl(Router.PLAYERS_URL))
                Router.navigate()

            this.logoutObservers.forEach(f => f())
        })
        CustomStorage.remove(CustomStorage.STORAGE_TOKEN)
    }

    restartDolfoConfig = () => (window as any).DOLFO_LANGUAGE_CONFIG = translate("DOLFO_LANGUAGE_CONFIG")

    render = () => {
        const { instance, loading } = this.state

        EntityCRUD.contextType = AppContext
        Home.contextType = AppContext

        return <AppContext.Provider value={{
            getUser: () => this.state.user,
            changeLanguage: language => {
                this.setInternalState({ language }, "Changed language", () => this.langObservers.forEach(e => e()))
                CustomStorage.set(CustomStorage.STORAGE_LANG, language)
                this.restartDolfoConfig()
            },
            getNations: () => this.state.nations,
            logout: this.logout,
            login: login => this.setInternalState({
                user: login as IApiUser & IApiGPlayer
            }, "Logged in", () => Router.navigate()),
            addLangObserver: fn => this.langObservers.push(fn) - 1,
            removeLangObserver: index => this.langObservers.splice(index, 1),
            addLogoutObserver: fn => this.logoutObservers.push(fn) - 1,
            removeLogoutObserver: index => this.logoutObservers.splice(index, 1),
            getTournamentTypes: () => this.state.tournament_types
        }}>
            {loading && <span className="loading-bar"></span>}

            {Router.showNavbar() ? <>
                <Navbar />

                <div className="content">
                    <div className="mb-3">
                        {instance}
                    </div>

                    <Footer />
                </div>
            </> : instance}
        </AppContext.Provider>
    }
}