import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { faCopyright } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { translate } from "../utils/LangUtils"

export class Footer extends React.Component<{ readonly lightText?: boolean }>{
    render = () => {
        const { lightText } = this.props

        return <div className="small text-center text-muted">
            <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}&nbsp;

            <a href="https://pokemonmillennium.net" target="_blank" rel="noreferrer" className={"link-"  + (lightText ? "light" : "primary")}>Pokémon Millennium</a>

            <span className="px-2">•</span>

            <a href="https://www.pokemonmillennium.net/termini-e-condizioni-di-utilizzo" target="_blank" rel="noreferrer" className={"link-"  + (lightText ? "light" : "primary")}>
                {translate("termsConditions")}
            </a>

            <br />

            Created by <a href="https://github.com/Leio99" target="_blank" rel="noreferrer" className={"fw-semibold link-"  + (lightText ? "light" : "primary")}>
                <FontAwesomeIcon icon={faGithub} /> Leio99
            </a>

            <span className="px-2">•</span>

            Approved by <strong>TPCi</strong>
        </div>
    }
}