import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Axios, { AxiosError } from "axios"
import { Toast } from "dolfo2"
import _ from "lodash"
import React from "react"
import { CustomStorage } from "../utils/CustomStorage"
import { translate } from "../utils/LangUtils"

export class ServerCall {
    private static readonly API_URL = process.env.REACT_APP_API_URL

    protected static toURLParams = (body: any) => {
        const params = new URLSearchParams(body as Record<string, string>)

        Object.keys(body).forEach(k => {
            if (body[k] == null || body[k] === "")
                params.delete(k)
        })

        return params
    }

    private static getHeaders = () => CustomStorage.has(CustomStorage.STORAGE_TOKEN) ? {
        Authorization: "Bearer " + CustomStorage.get(CustomStorage.STORAGE_TOKEN)
    } : {}

    public static get = <T>(url: string): Promise<T> => {
        const promise = Axios.get<T>(this.API_URL + url, { headers: this.getHeaders() })

        return promise.then(({ data }) => data)
    }

    public static post = <T>(url: string, body?: any): Promise<T> => {
        const promise = Axios.post<T>(this.API_URL + url, body, { headers: this.getHeaders() })

        return promise.then(({ data }) => data)
    }

    public static put = <T>(url: string, body?: any): Promise<T> => {
        const promise = Axios.put<T>(this.API_URL + url, body, { headers: this.getHeaders() })

        return promise.then(({ data }) => data)
    }

    public static delete = <T>(url: string, body?: any): Promise<T> => {
        const promise = Axios.delete<T>(this.API_URL + url, { data: body, headers: this.getHeaders() })

        return promise.then(({ data }) => data)
    }
}

export const showMessage = (message: string, icon = faExclamationCircle, iconColor = "red") => Toast.show({
    message: React.createElement(React.Fragment, {
        children: [
            React.createElement(FontAwesomeIcon, {
                icon,
                key: "icon",
                className: "fc-" + iconColor
            }),
            React.createElement("span", { key: "text", className: "ms-2" }, message)
        ]
    }),
    position: "top"
})

export const makeApiCall = <T>(params: {
    promise: Promise<T>,
    onSuccess?: (data: T) => void,
    onError?: (err: AxiosError) => T
    doFinally?: () => void
}) => params.promise.then(data => params.onSuccess ? params.onSuccess(data) : data).catch((err: AxiosError) => {
    if (err.response){
        if(err.response.status !== 307 && _.isString(err.response.data))
            showMessage(translate("apiErrors." + err.response.data))
    }else
        showMessage(translate("apiErrors.unableToComplete"))

    if (params.onError)
        return params.onError(err)
}).finally(params.doFinally)