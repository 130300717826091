import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import { Button } from "dolfo2"
import { jwtDecode } from "jwt-decode"
import React from "react"
import { AuthService } from "../apis/AuthService"
import { makeApiCall } from "../apis/ServerCall"
import { GUserInfos } from "../interfaces/GUserInfos"
import { IContext } from "../interfaces/IContext"
import { CustomStorage } from "../utils/CustomStorage"
import { translate } from "../utils/LangUtils"
import { LoaderClass } from "../utils/LoaderClass"
import { Router } from "../utils/Router"
import { Footer } from "./Footer"
import { AppContext } from "./Root"

const GOOGLE_CLIENT_ID = "378827783616-fng1guprijctc4kksoq4ir4lq98g82jt.apps.googleusercontent.com"

export class Login extends LoaderClass<unknown, boolean>{
    public isAuthorized = () => !CustomStorage.has(CustomStorage.STORAGE_TOKEN)

    private fetchGoogleUserInfos = ({ credential }: CredentialResponse, login: IContext["login"]) => {
        const userInfos = jwtDecode<GUserInfos>(credential)

        this.toggleLoading()

        makeApiCall({
            promise: AuthService.findGoogleUser(userInfos.email, userInfos.sub).then(token => {
                CustomStorage.set(CustomStorage.STORAGE_TOKEN, token)
                return AuthService.getSession()
            }),
            onSuccess: user => login(user),
            onError: err => {
                const { status } = err.response

                if(status === 307)
                    Router.navigate(Router.GOOGLE_REGISTER_URL, userInfos)

                return null
            },
            doFinally: () => this.toggleLoading()
        })
    }

    render = () => {
        const { loading } = this.state
        
        return <div className="login-container">
            <img src={Router.getBaseUrl() + "images/logo_pm.svg"} className="mb-3" alt="pm-logo" />

            <div className="login-box pm-card mb-3">
                <h2 className="text-center">
                    {translate("login.title")}
                </h2>

                <AppContext.Consumer>
                    {
                        ({ login }) => <div className="text-center">
                            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                <div className="g-button">
                                    <GoogleLogin onSuccess={resp => this.fetchGoogleUserInfos(resp, login)} />
                                </div>
                            </GoogleOAuthProvider>

                            <div className="text-muted my-2 text-center">
                                {translate("login.or")}
                            </div>

                            <Button color="blue2" size="large" className="text-uppercase" disabled={loading} onClick={() => Router.navigate(Router.ADMIN_LOGIN)}>
                                {translate("login.adminAuth")}
                            </Button>
                        </div>
                    }
                </AppContext.Consumer>
            </div>

            <Footer lightText />
        </div>
    }
}