import { Input } from "dolfo2"
import _ from "lodash"
import { IBaseID } from "pkm-special-tool"
import React, { createRef } from "react"
import ReactDOM from "react-dom"
import { makeApiCall } from "../apis/ServerCall"
import { IEntityList } from "../interfaces/IUtils"
import { EntityCRUD } from "./EntityCRUD"
import { Flaggable } from "./Flaggable"
import { translate } from "./LangUtils"

interface IState<T extends IBaseID> extends IEntityList<T>{
    readonly filter: string
}

export abstract class SearchInput<T extends IBaseID, P = unknown, S = unknown> extends Flaggable<P, boolean, IState<T> & S>{
    private readonly TIMING = 500
    private typing: _.DebouncedFunc<() => void>
    private readonly AUTOCOMPLETE_EXLUDE_KEYS = ["Alt", "Control", "Tab", "Enter", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Shift","CapsLock", "ContextMenu", "Meta", "Escape"]
    private inputRef = createRef<Input>()

    protected minFilterLength = 1

    constructor(props: P, state?: S){
        super(props, {
            ...state,
            filter: "",
            list: []
        })

        this.typing = _.debounce(() => {
            const { filter } = this.state

            if(!filter?.trim())
                return this.setInternalState({ list: [] } as typeof this.state, "Reset search list")

            if(filter.length < this.minFilterLength)
                return

            this.toggleLoading()

            makeApiCall({
                promise: this.getSource(filter).then(list => this.setInternalState({
                    list,
                    loading: false
                } as typeof this.state, "Loaded search list", () => {
                    const inputNode = ReactDOM.findDOMNode(this.inputRef.current) as HTMLElement

                    inputNode.querySelector("input").focus()
                }))
            })
        }, this.TIMING)
    }

    private onChange = (filter: string): void => this.setInternalState({ filter, list: [] } as typeof this.state, "Filter changed")

    private onKeyUp = (e: React.KeyboardEvent): void => {
        if(this.AUTOCOMPLETE_EXLUDE_KEYS.includes(e.key) || (e.ctrlKey && e.key.toLowerCase() !== "v"))
            return

        this.typing()
    }

    protected abstract getSource: (_filter: string) => Promise<T[]>

    protected abstract customRender: () => React.ReactNode

    protected isDisabled = () => false

    protected renderSearchBlock = () => {
        const { loading, filter } = this.state

        return <>
            <Input
                ref={this.inputRef}
                defaultValue={filter}
                onChange={v => this.onChange(v as string)}
                placeHolder={translate("searchInput.placeholder", { min: this.minFilterLength })}
                disabled={loading || this.isDisabled()}
                onKey={(e, type) => {
                    if(type === "keyup")
                        this.onKeyUp(e)
                }}
            />

            {loading && <div className="mb-2">
                {EntityCRUD.centeredLoading()}
            </div>}

            {this.customRender()}
        </>
    }

    render = this.renderSearchBlock
}