import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { faBars, faLanguage, faPowerOff, faRightToBracket, faTrophy, faUpload, faUserCircle, faUserShield } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, Dropdown, EventManager, FullColors, OnClick, OnMouseWheel, OptionButtonProps } from "dolfo2"
import moment from "moment"
import { IApiGPlayer, IApiUser, IGPlayer, RolesPermissionsType } from "pkm-special-tool"
import React from "react"
import { Flaggable } from "../utils/Flaggable"
import { Lang, getCurrentLanguage, translate } from "../utils/LangUtils"
import { Router } from "../utils/Router"
import { AppContext } from "./Root"
import { ImportPlayersDialog } from "./players/ImportPlayersDialog"

interface NavbarButton extends Required<OnClick>, OnMouseWheel{
    readonly icon: IconProp
    readonly color: FullColors
    readonly permissions: RolesPermissionsType[]
    readonly label: string
    readonly outline: () => boolean
    readonly checkUserPermission?: (u: IApiUser & IApiGPlayer) => boolean
}

export class Navbar extends Flaggable{
    private event: EventManager
    private readonly BUTTONS: NavbarButton[] = [
        {
            permissions: [RolesPermissionsType.MANAGE_TOURNAMENTS, RolesPermissionsType.MANAGE_PLAYERS],
            label: "pages.tournaments",
            color: "red",
            icon: faTrophy,
            outline: () => !Router.isUrl(Router.TOURNAMENTS_URL),
            onClick: () => Router.navigate(Router.TOURNAMENTS_URL),
            onMouseWheel: () => window.open(Router.getBaseUrl() + Router.TOURNAMENTS_URL, "_blank"),
            checkUserPermission: u => u && !u.isGoogle
        },
        {
            permissions: [RolesPermissionsType.IMPORT_PLAYERS],
            label: "pages.importPlayers",
            color: "blue2",
            icon: faUpload,
            outline: () => true,
            onClick: () => this.openImportPlayers()
        }
    ]

    componentDidMount = () => this.event = new EventManager("resize", () => {
        const { flag } = this.state,
        newFlag = window.innerWidth < 750

        if(newFlag !== flag)
            this.setFlag(window.innerWidth < 750)
    }).activateOnRegister().register()

    componentWillUnmount = () => this.event.unregister()

    private openImportPlayers = () => Dialog.openComponent(ImportPlayersDialog)

    private openUserInfos = ({ first_name, last_name, email, player_id, birth_date, pronoun }: IGPlayer) => Dialog.open({
        title: translate("userInfo.title"),
        hideFooter: true,
        closeOnMask: true,
        width: 300,
        children: <>
            <h5 className="mb-0">{first_name} {last_name}</h5>
            <small>{email}</small>

            <div className="mt-3">
                <strong>{translate("players.userId")}</strong>: {player_id}
            </div>
            <div>
                <strong>{translate("players.birthDate")}</strong>: {moment(new Date(birth_date)).format(translate("dateFormat"))}
            </div>
            <div>
                <strong>{translate("players.pronoun")}</strong>: {pronoun}
            </div>
        </>
    })
    
    render = () => <div className="header shadow-sm">
        <a href={Router.getBaseUrl()} rel="noreferrer" onClick={e => {
            e.preventDefault()
            Router.navigate()
        }}>
            <img src={Router.getBaseUrl() + "images/logo_pm.svg"} alt="pm-logo" className="pm-logo" />
        </a>

        <AppContext.Consumer>
            {
                ({ logout, changeLanguage, getUser }) => {
                    const lang = getCurrentLanguage(),
                    user = getUser(),
                    hasPermission = (key: RolesPermissionsType) => user?.permissions?.[key],
                    { flag } = this.state,
                    buttons = this.BUTTONS.filter(b => b.permissions.some(hasPermission) || (b.checkUserPermission && b.checkUserPermission(user))),
                    options: OptionButtonProps[] = buttons.filter(() => flag).map(btn => ({
                        children: <div className="d-flex align-items-center gap-2">
                            <FontAwesomeIcon fixedWidth icon={btn.icon} /> {translate(btn.label)}
                        </div>,
                        onClick: btn.onClick
                    }))

                    if(user){
                        options.splice(0, 0, {
                            className: !user.isGoogle ? "login-info" : "",
                            children: <div className="d-flex align-items-center">
                                <FontAwesomeIcon fixedWidth icon={!user.isGoogle ? faUserCircle : faGoogle} className="me-2" />

                                <span className="d-flex flex-column">
                                    <strong>{user.username || (`${user.first_name} ${user.last_name}`)}</strong>
                                    {user.email && <small>{user.email}</small>}
                                    {user.organizer_name && <small>{user.organizer_name}</small>}
                                </span>
                            </div>,
                            onClick: () => user.isGoogle && this.openUserInfos(user)
                        })
                    }

                    if(hasPermission(RolesPermissionsType.DELETE_PLAYERS)){
                        options.push({
                            children: <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon fixedWidth icon={faUserShield} /> {translate("pages.managePlayers")}
                            </div>,
                            onClick: () => Router.navigate(Router.PLAYERS_URL)
                        })
                    }

                    if(flag){
                        options.push({
                            children: <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon fixedWidth icon={faLanguage} /> {translate("lang")}: <strong>
                                    {lang.toUpperCase()}
                                </strong>
                            </div>,
                            onClick: () => changeLanguage(lang === Lang.EN ? Lang.IT : Lang.EN)
                        })
                    }

                    if(user){
                        options.push({
                            children: <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon fixedWidth icon={faPowerOff} /> {translate("exit")}
                            </div>,
                            onClick: logout
                        })
                    }else{
                        options.push({
                            children: <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon fixedWidth icon={faRightToBracket} /> {translate("login.title")}
                            </div>,
                            onClick: () => Router.navigate(Router.LOGIN_URL)
                        })
                    }

                    return <>
                        <span className="ms-4"></span>

                        {
                            buttons.length > 0 && !flag && buttons.map(btn => <Button
                                key={btn.label}
                                className="me-2"
                                shape="pill"
                                color={btn.color}
                                outline={btn.outline()}
                                onClick={btn.onClick}
                                onMouseWheel={btn.onMouseWheel}>
                                    <FontAwesomeIcon icon={btn.icon} />
                                    <span className="ms-2">{translate(btn.label)}</span>
                            </Button>)
                        }

                        {flag ? <div className="ms-auto"></div> : <>
                            <Button outline={lang === Lang.EN} size="small" shape="pill" color="blue" className="px-3 ms-auto" onClick={() => changeLanguage(Lang.IT)}>
                                IT
                            </Button>

                            <Button outline={lang === Lang.IT} size="small" shape="pill" className="mx-2 px-3" color="blue" onClick={() => changeLanguage(Lang.EN)}>
                                EN
                            </Button>
                        </>}

                        <Dropdown key={JSON.stringify(user)} closeAfterSelect options={options}>
                            <Button color="white" shape="pill">
                                <FontAwesomeIcon icon={faBars} />
                            </Button>
                        </Dropdown>
                    </>
                }
            }
        </AppContext.Consumer>
    </div>
}