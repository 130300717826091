import { faCheckCircle, faChevronLeft, faExternalLink, faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, EventManager, Form, FormButton, FormControlsObject, FormGroup, FormInputProps, IColumn, Table, TableColumn, TableOrderButton, TableToolbar, Tooltip, addToRegister, unregisterAll } from "dolfo2"
import { IBaseID, RolesPermissionsType } from "pkm-special-tool"
import React from "react"
import { BaseCrudAPI } from "../apis/BaseCrudAPI"
import { makeApiCall, showMessage } from "../apis/ServerCall"
import { DetailType, EColumn, IEntityList } from "../interfaces/IUtils"
import { Flaggable } from "./Flaggable"
import { translate } from "./LangUtils"

export type IEntityCRUD<T extends IBaseID> = new (...args: any) => EntityCRUD<T>

export enum SaveType{ CREATE, UPDATE }

export abstract class EntityCRUD<T extends IBaseID, P = unknown, S = unknown> extends Flaggable<P, T, IEntityList<T> & S>{
    private tableRef: Table<T>
    private tablePage: number
    private apiService: BaseCrudAPI<T>
    private events: EventManager[] = []
    private tableFilters: {
        [x: string]: string | number | boolean;
    }
    private disableEscape = false

    public abstract apiURL: string
    public abstract getColumns: () => EColumn<T>[]
    protected buildForm: (item: T) => DetailType<T>

    protected form: FormGroup<T>
    protected builtForm: DetailType<T>
    protected onlyInsertForm = false

    componentDidMount = () => {
        this.apiService = new BaseCrudAPI<T>(this.apiURL)

        if(this.onlyInsertForm)
            return this.openDetail({} as T)

        this.toggleLoading()

        makeApiCall({
            promise: Promise.all([
                this.apiService.getList(),
                this.init()
            ]),
            onSuccess: ([list]) => this.setInternalState({ list } as typeof this.state, "Loaded list"),
            doFinally: () => this.toggleLoading()
        })

        addToRegister(this.events, new EventManager("keyup", (e: KeyboardEvent) => {
            if(e.code === "Escape" && this.state.flag && !this.state.loading && !this.disableEscape)
                this.closeDetail()
        }))
    }

    componentWillUnmount = () => unregisterAll(this.events)

    protected toggleDisableEscape = () => this.disableEscape = !this.disableEscape

    protected init = (): Promise<unknown> => Promise.resolve()

    protected onOpenDetail: (item: T) => void
    
    public getData = (items: T[]): T[] => items

    protected isNew = (item: T = this.state.flag) => item && Object.keys(item).length === 0

    protected getActions = (_item: T) => <></>

    protected getToolbarActions = () => <></>

    protected getGridToolbarActions = () => <></>

    protected hasPermission = (key: RolesPermissionsType) => this.context.getUser()?.permissions?.[key]

    protected openDetail = (item: T) => {
        if(this.state.loading || !this.buildForm)
            return

        if(this.tableRef){
            this.tableFilters = this.tableRef.state.filters
            this.tablePage = this.tableRef.state.page
        }

        let tmp: Partial<typeof this.builtForm> = {}
        const tmpForm = this.buildForm(item)
       
        Object.keys(tmpForm).forEach(k => {
            const obj = tmpForm[k as keyof Omit<T, "id">]

            tmp[k as keyof T] = {
                ...obj,
                value: item[k as keyof T] ?? obj.value
            }
        })

        this.builtForm = tmp
        
        this.form = new FormGroup<T>(this.builtForm as FormControlsObject<T>)

        this.setFlag(item)

        if(this.onOpenDetail)
            this.onOpenDetail(item)

        if((!this.canSave(SaveType.CREATE, item) && this.isNew(item)) || (!this.canSave(SaveType.UPDATE, item) && !this.isNew(item)))
            this.form.disable()
    }

    protected canSave = (_saveType: SaveType, _item: T) => true

    protected buildParamComponent = <T2,>(component: React.ComponentType<T2 & FormInputProps>, props?: Omit<T2 & FormInputProps, "controlName">) => ({
        component,
        props
    })

    private submit = () => {
        const form = this.form.getObjectValue(),
        { state, apiService } = this,
        { flag, list } = state,
        isNew = Object.keys(flag).length === 0,
        method = isNew ? apiService.post(form) : apiService.put({ ...flag, ...form })

        this.toggleLoading()

        makeApiCall({
            promise: method,
            onSuccess: resp => {
                const newList = isNew ? list?.concat(resp) : list.map(l => l.id === resp.id ? resp : l)
                
                this.setState({
                    list: newList,
                    flag: this.onlyInsertForm ? {} : resp
                } as typeof state)
    
                showMessage(translate("masterDetail.saved"), faCheckCircle, "green")
            },
            doFinally: this.toggleLoading
        })
    }

    public static openLoadingDialog = () => Dialog.open({
        title: null,
        hideFooter: true,
        className: "loading-dialog",
        children: this.centeredLoading(),
        width: 300
    })

    private delete = (item: T) => Dialog.confirm(
        translate("warning"),
        translate("masterDetail.confirmDelete"),
        () => {
            this.closeDetail()
            this.toggleLoading()

            makeApiCall({
                promise: this.apiService.delete(item),
                onSuccess: () => {
                    const list = this.state.list.filter(l => l.id !== item.id)
        
                    this.setState({ list } as typeof this.state)
        
                    showMessage(translate("masterDetail.deleted"), faCheckCircle, "green")
                },
                doFinally: this.toggleLoading
            })

            return true
        }
    )

    protected closeDetail = () => this.setFlag(null)

    public static centeredLoading = () => <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} spin /> {translate("loading")}
    </div>

    protected canDelete = () => false

    protected renderDetail = () => {
        const { onlyInsertForm, state } = this,
        { loading } = state

        return <Form frm={this.form} onSubmit={this.submit} className={!onlyInsertForm ? "pm-card" : ""}>
            {!onlyInsertForm && <h4 className="d-flex align-items-center gap-1">
                <Tooltip label={translate("back")}>
                    <Button shape="pill" color="white" onClick={this.closeDetail} disabled={loading}>
                        <FontAwesomeIcon icon={faChevronLeft} type="far" />
                    </Button>
                </Tooltip>
                {this.isNew() ? translate("new") : translate("edit")}
            </h4>}

            {
                Object.keys(this.builtForm).filter(key => this.builtForm[key as keyof T].render).map(key => {
                    const val = this.builtForm[key as keyof T],
                    { component, props } = val.render()

                    return React.createElement(component, {
                        ...props,
                        key,
                        controlName: key,
                        disabled: loading
                    })
                })
            }

            <div className="d-flex gap-3">
                {this.getToolbarActions()}
                
                <FormButton shape="pill" className="ms-auto" loading={loading} color="green">
                    {translate("save")}
                </FormButton>
            </div>
        </Form>
    }

    protected getRowClass = (item: T, col: IColumn<T>): string => undefined

    render = () => {
        const { list, flag, loading } = this.state

        return flag ? this.renderDetail() : !list ? <div className="pm-card bordered">
            {EntityCRUD.centeredLoading()}
        </div> : <Table<any & T> data={this.getData(list)} pagination={25} onDoubleClickRow={a => this.openDetail(a as T)} rowClass={this.getRowClass} ref={r => {
            this.tableRef = r

            if(r && this.tableFilters && this.tablePage){
                r.setState({ filters: this.tableFilters })
                this.tableFilters = null
            }

            if(r && this.tablePage){
                setTimeout(() => {
                    r.setState({ page: this.tablePage })
                    this.tablePage = null
                })
            }
        }}>
            <TableToolbar>
                <div className="d-flex align-items-center gap-1">
                    <label className="me-auto">
                        {translate("masterDetail.results")}
                    </label>

                    {this.getGridToolbarActions()}
                    
                    {this.canSave(SaveType.CREATE, null) && this.buildForm && <Tooltip label={translate("masterDetail.create")}>
                        <Button color="green" shape="pill" onClick={() => this.openDetail({} as T)} disabled={loading}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Tooltip>}
                    
                    <TableOrderButton />
                </div>
            </TableToolbar>

            {
                this.getColumns().map(c => <TableColumn key={c.label} {...c}>
                    {c.extract}
                </TableColumn>)
            }

            {(this.buildForm || this.canDelete()) && <TableColumn label={translate("masterDetail.actions")} align="center" width="10%">
                {
                    d => <div className="d-flex gap-3 justify-content-center">
                        {this.buildForm && <Tooltip label={translate("masterDetail.openDetail")}>
                            <Button shape="text" color="blue2" disabled={loading} onClick={() => this.openDetail(d as T)}>
                                <FontAwesomeIcon size="lg" icon={faExternalLink} />
                            </Button>
                        </Tooltip>}

                        {this.getActions(d)}

                        {
                            this.canDelete() && <Tooltip label={translate("delete")}>
                            <Button shape="text" color="red" disabled={loading} onClick={() => this.delete(d as T)}>
                                <FontAwesomeIcon size="lg" icon={faTrash} />
                            </Button>
                        </Tooltip>}
                    </div>
                }
            </TableColumn>}
        </Table>
    }
}