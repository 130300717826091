import { faCheck, faCheckCircle, faCircleNotch, faTimes, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, ComponentDialogProps, Dialog, Form, FormButton, FormFileselect, FormGroup, Step, Stepper, StepperButton, Table, TableColumn } from "dolfo2"
import { XMLParser } from "fast-xml-parser"
import _ from "lodash"
import moment from "moment"
import { ICheckPlayer, IPlayer } from "pkm-special-tool"
import React, { createRef } from "react"
import { ServerCall, makeApiCall, showMessage } from "../../apis/ServerCall"
import { PlayersKeysNeeded, PlayersXML } from "../../interfaces/PlayersXML"
import { EntityCRUD } from "../../utils/EntityCRUD"
import { Flaggable } from "../../utils/Flaggable"
import { translate } from "../../utils/LangUtils"
import { getPlayerColumns } from "./SubscribedPlayers"

interface GridPlayer extends Partial<IPlayer>{
    readonly valid: boolean
}

export class ImportPlayersDialog extends Flaggable<ComponentDialogProps, number>{
    private frm = new FormGroup({
        file: { required: true }
    })

    private stepperRef = createRef<Stepper>()
    private gridData: GridPlayer[] = []

    getValidPlayers = () => this.gridData.filter(p => p.valid)

    readXML = () => {
        const value = this.frm.get("file") as FileList,
        file = value[0]

        this.toggleLoading()

        makeApiCall({
            promise: file.text().then(text => {
                const parser = new XMLParser({
                    ignoreAttributes: false,
                    attributeNamePrefix: ""
                }),
                playersJSON = parser.parse(text) as PlayersXML
    
                if(!this.isValidXML(playersJSON))
                    return Promise.reject({ response: { data: "invalidXML" }})
    
                const { player } = playersJSON.players
    
                return player.map(p => ({
                    lastName: p.lastname,
                    firstName: p.firstname,
                    birthDate: new Date(p.birthdate),
                    user_id: Number(p.userid),
                    creationDate: p.creationdate ? new Date(p.creationdate) : null,
                    valid: PlayersKeysNeeded.every(key => Object.keys(p).includes(key))
                }))
            }),
            onSuccess: players => this.gridData = players,
            doFinally: this.toggleLoading,
            onError: () => {
                this.prev()
                return null
            }
        })
    }

    isValidXML = (json: PlayersXML) => json?.players?.player && _.isArray(json.players.player)

    next = () => this.stepperRef.current.next()

    prev = () => this.stepperRef.current.prev()

    import = () => {
        const validPlayers = this.getValidPlayers()

        this.toggleLoading()

        makeApiCall({
            promise: ServerCall.post<number[]>("players/import", validPlayers),
            onSuccess: errors => {
                if(errors.length > 0){
                    Dialog.open({
                        title: <>
                            <FontAwesomeIcon icon={faWarning} className="fc-yellow" /> {translate("warning")}
                        </>,
                        hideFooter: true,
                        children: <>
                            <p>{translate("importPlayers.positionsInError")}:</p>

                            {this.renderPlayersTable(this.gridData.filter((_, i) => errors.includes(i)), false)}
                        </>
                    })
                }

                this.props.close()
                showMessage(translate("importPlayers.importedSuccessfully"), faCheckCircle, "green")
            },
            doFinally: this.toggleLoading
        })
    }

    renderPlayersTable = (data: GridPlayer[], showValidCol = true) => <Table<GridPlayer> data={data} pagination={25} rowStyle={r => !r.valid ? {
        backgroundColor: "rgba(223, 34, 34, 0.25)"
    } : null}>
        {
            getPlayerColumns().map(c => <TableColumn<ICheckPlayer> key={c.label} label={c.label} align={c.align}>
                {c.extract}
            </TableColumn>)
        }

        <TableColumn<GridPlayer> label={translate("players.birthDate")} align="center">
            {
                d => moment(new Date(d.birthDate)).format(translate("dateFormat"))
            }
        </TableColumn>

        {showValidCol && <TableColumn<GridPlayer> label={translate("importPlayers.colValidForImport")} align="center">
            {
                d => <FontAwesomeIcon icon={d.valid ? faCheck : faTimes} />
            }
        </TableColumn>}
    </Table>

    render = () => {
        const { loading } = this.state

        return <Dialog visible title={translate("importPlayers.title")} hideFooter className="top-dialog">
            <Stepper ref={this.stepperRef}>
                <Step label={translate("importPlayers.step1")}>
                    <Form onSubmit={() => {
                        this.next()
                        this.readXML()
                    }} frm={this.frm}>
                        <FormFileselect controlName="file" type="drop-area" accept=".xml" />

                        <div className="d-flex justify-content-end">
                            <FormButton shape="pill" color="green">
                                {translate("importPlayers.next")}
                            </FormButton>
                        </div>
                    </Form>
                </Step>
                <Step label={translate("importPlayers.step2")}>
                    {loading ? EntityCRUD.centeredLoading() : this.renderPlayersTable(this.gridData)}

                    <div className="dolfo-control"></div>

                    <StepperButton action="prev" shape="text" color="red">
                        {translate("importPlayers.back")}
                    </StepperButton>
                    <StepperButton action="next" shape="pill" color="green">
                        {translate("importPlayers.next")}
                    </StepperButton>
                </Step>
                <Step label={translate("importPlayers.step3")}>
                    <Alert className="dolfo-control" color="yellow" title={<span>
                        <FontAwesomeIcon icon={faWarning} /> {translate("warning")}
                    </span>} style={{ whiteSpace: "pre" }}>
                        {translate("importPlayers.warningText", {
                            invalid: this.gridData.length - this.getValidPlayers().length
                        })}
                    </Alert>

                    <StepperButton action="prev" shape="text" color="red">
                        {translate("importPlayers.back")}
                    </StepperButton>
                    <StepperButton action="next" shape="pill" color="green" onClick={this.import}>
                        {translate("importPlayers.import")}
                    </StepperButton>
                </Step>
                <Step label={translate("importPlayers.step4")}>
                    <div className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faCircleNotch} spin className="fa-5x mb-2" />
                        <span>{translate("importPlayers.importing")}</span>
                    </div>
                </Step>
            </Stepper>
        </Dialog>
    }
}