import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Checkbox, ComponentDialogProps, Dialog } from "dolfo2"
import { IBaseID, IPermissions, RolesPermissionsType } from "pkm-special-tool"
import React from "react"
import { makeApiCall, ServerCall, showMessage } from "../apis/ServerCall"
import { CanLoad } from "../utils/CanLoad"
import { translate } from "../utils/LangUtils"

export class AssignPermissionsDialog extends CanLoad<ComponentDialogProps & IBaseID, IPermissions>{
    componentDidMount = () => {
        this.toggleLoading()

        makeApiCall({
            promise: ServerCall.get<IPermissions>("players/permissions/" + this.props.id),
            onSuccess: permissions => this.setInternalState(permissions || {}, "Loaded players permissions"),
            doFinally: () => this.toggleLoading()
        })
    }

    savePermissions = () => {
        this.toggleLoading()

        makeApiCall({
            promise: ServerCall.post("players/permissions/" + this.props.id, this.state),
            onSuccess: () => {
                showMessage(translate("masterDetail.saved"), faCheckCircle, "green")
                this.props.close()
            },
            doFinally: () => this.toggleLoading(),
        })
    }

    toggle = (k: keyof IPermissions) => this.setInternalState({
        ...this.state,
        [k]: !this.state[k]
    }, "Toggled check")
    
    render = () => <Dialog visible title={translate("players.assignPermissions")} customFooter={<Button color="green" loading={this.state.loading} shape="pill" onClick={this.savePermissions}>
        {translate("save")}
    </Button>} className="top-dialog">
        {
            Object.values(RolesPermissionsType).filter(v => v !== RolesPermissionsType.ASSIGN_PERMISSIONS).map(r => <Checkbox
                key={r}
                checked={this.state[r as keyof IPermissions]}
                disabled={this.state.loading}
                label={translate(`permissions.${r}`)}
                onChange={() => this.toggle(r)}
            />)
        }
    </Dialog>
}