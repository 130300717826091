import { FormDatepicker, FormInput } from "dolfo2"
import { IBaseID, ICheckPlayer } from "pkm-special-tool"
import { EColumn } from "../../interfaces/IUtils"
import { EntityCRUD } from "../../utils/EntityCRUD"
import { translate } from "../../utils/LangUtils"

export class PlayersForm extends EntityCRUD<ICheckPlayer, IBaseID>{
    public apiURL = "players?tournament_id=" + this.props.id
    protected onlyInsertForm = true

    protected buildForm = () => ({
        user_id: {
            required: true,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("players.userId"),
                max: 9999999,
                type: "number",
                steps: 1,
                decimals: 0
            })
        },
        birthDate: {
            required: true,
            render: () => this.buildParamComponent(FormDatepicker, {
                label: translate("players.birthDate"),
                dateFormat: translate("dateFormat")
            })
        },
        firstName: {
            required: true,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("players.firstName"),
                maxLength: 100
            })
        },
        lastName: {
            required: true,
            render: () => this.buildParamComponent(FormInput, {
                label: translate("players.lastName"),
                maxLength: 100
            })
        }
    })

    public getColumns = (): EColumn<ICheckPlayer>[] => []
}