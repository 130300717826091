import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Form, FormButton, FormGroup, FormInput, Tooltip } from "dolfo2"
import { ILogin } from "pkm-special-tool"
import React from "react"
import { AuthService } from "../apis/AuthService"
import { makeApiCall } from "../apis/ServerCall"
import { IContext } from "../interfaces/IContext"
import { CustomStorage } from "../utils/CustomStorage"
import { translate } from "../utils/LangUtils"
import { LoaderClass } from "../utils/LoaderClass"
import { Router } from "../utils/Router"
import { Footer } from "./Footer"
import { AppContext } from "./Root"

export class AdminLogin extends LoaderClass<unknown, boolean>{
    private form = new FormGroup<ILogin>({
        username: { value: "", required: true },
        password: { value: "", required: true }
    })

    public isAuthorized = () => !CustomStorage.has(CustomStorage.STORAGE_TOKEN)

    private submit = (login: IContext["login"]) => {
        this.toggleLoading()

        makeApiCall({
            promise: AuthService.login(this.form.getObjectValue()).then(token => {
                CustomStorage.set(CustomStorage.STORAGE_TOKEN, token)
                return AuthService.getSession()
            }),
            onSuccess: user => login(user),
            doFinally: () => this.toggleLoading()
        })
    }

    render = () => {
        const { loading } = this.state
        
        return <div className="login-container">
            <img src={Router.getBaseUrl() + "images/logo_pm.svg"} className="mb-3" alt="pm-logo" />

            <div className="login-box pm-card mb-3">
                <h2>
                    <Tooltip label={translate("back")}>
                        <Button shape="pill" color="white" onClick={() => Router.navigate(Router.LOGIN_URL)} disabled={loading} className="me-1">
                            <FontAwesomeIcon icon={faChevronLeft} type="far" />
                        </Button>
                    </Tooltip>
                    {translate("login.title")}
                </h2>

                <AppContext.Consumer>
                    {
                        ({ login }) => <Form frm={this.form} onSubmit={() => this.submit(login)}>
                            <FormInput controlName="username" label="Username" disabled={loading} />
                            <FormInput controlName="password" label="Password" type="password" showPasswordToggle disabled={loading} />
                            <FormButton loading={loading} color="dark" size="full">
                                {translate("signin")}
                            </FormButton>
                        </Form>
                    }
                </AppContext.Consumer>
            </div>

            <Footer lightText />
        </div>
    }
}