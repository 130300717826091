import { IApiGPlayer, IApiUser } from "pkm-special-tool"
import React, { CElement, ReactNode } from "react"
import { makeApiCall } from "../apis/ServerCall"
import { CanLoad } from "./CanLoad"

export abstract class LoaderClass<T, S = unknown> extends CanLoad<T, S>{
    public abstract render: () => ReactNode

    public isAuthorized = (_user?: IApiUser & IApiGPlayer) => true
}

export type ILoaderClass<T, S = unknown> = new (...args: any) => LoaderClass<T, S>

export const retrieveAndInstance = <LT, T extends ILoaderClass<LT>>(Clazz: T, loadingToggler: (bool: boolean) => void, routerProps = {}) => new Promise<CElement<any, LoaderClass<LT, unknown>>>(resolve => {
    loadingToggler(true)

    makeApiCall({
        promise: Promise.resolve(routerProps),
        onSuccess: result => resolve(React.createElement(Clazz, result)),
        doFinally: () => loadingToggler(false)
    })
})

export type LoaderClassFn = <P, LT, T extends ILoaderClass<P, LT>>(type: T, rProps: P) => void