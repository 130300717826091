import React from "react"
import { LoaderClass } from "../utils/LoaderClass"
import { Router } from "../utils/Router"

export class Dashboard extends LoaderClass<unknown>{
    componentDidMount = () => Router.navigate(Router.LOCALS)
    
    render = () => <div className="d-flex flex-wrap justify-content-center gap-3">
        <div className="col-12 col-md-5 col-lg-4 col-xl-3 shadow-sm rounded category-card p-3" onClick={() => Router.navigate(Router.LOCALS)}>
            <img src="/images/locals1.png" alt="locals" />
            <img src="/images/locals2.png" alt="locals" />
        </div>
        <div className="col-12 col-md-5 col-lg-4 col-xl-3 shadow-sm rounded category-card p-3" onClick={() => Router.navigate(Router.REGIONALS)}>
            <img src="/images/regionals.png" alt="regionals" />
        </div>
    </div>
}