import { faCheck, faCheckCircle, faSquareCheck, faSquareXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Tooltip } from "dolfo2"
import moment from "moment"
import { IBaseID, ICheckPlayer } from "pkm-special-tool"
import React from "react"
import { ServerCall, makeApiCall, showMessage } from "../../apis/ServerCall"
import { EColumn } from "../../interfaces/IUtils"
import { EntityCRUD } from "../../utils/EntityCRUD"
import { translate } from "../../utils/LangUtils"

export const SUBSCRIPTIONS_URL = "tournaments-subscriptions"

export const getPlayerColumns = (): EColumn<ICheckPlayer>[] => [
    {
        label: translate("players.userId"),
        align: "right",
        extract: d => d.user_id,
        filter: { key: "user_id" },
        order: { key: "user_id", type: "number" }
    },
    {
        label: translate("players.firstName"),
        extract: d => d.firstName,
        filter: { key: "firstName" },
        order: { key: "firstName" }
    },
    {
        label: translate("players.lastName"),
        extract: d => d.lastName,
        filter: { key: "lastName" },
        order: { key: "lastName" }
    },
    {
        label: translate("players.birthDate"),
        extract: d => moment(new Date(d.birthDate)).format(translate("dateFormat")),
        align: "center"
    }
]

interface IProps extends IBaseID{
    readonly showChecks: boolean
}

export class SubscribedPlayers extends EntityCRUD<ICheckPlayer, IProps>{
    public readonly apiURL = SUBSCRIPTIONS_URL + "/" + this.props.id

    public getColumns = () => this.props.showChecks ? getPlayerColumns().concat({
        label: translate("players.confirmed"),
        align: "center",
        extract: d => d.checked ? <FontAwesomeIcon icon={faCheck} /> : <></>
    }) : getPlayerColumns()

    protected canDelete = () => true

    protected toggleCheck = (item: ICheckPlayer) => {
        this.toggleLoading()

        makeApiCall({
            promise: ServerCall.post(this.apiURL + "/toggle-check", { player_id: item.id }),
            onSuccess: () => this.setInternalState({
                list: this.state.list.map(l => {
                    if(l.id === item.id)
                        return { ...l, checked: !l.checked }
                    return l
                })
            }, "Toggled check", () => showMessage(translate("masterDetail.saved"), faCheckCircle, "green")),
            doFinally: this.toggleLoading
        })
    } 
    
    protected getActions = (item: ICheckPlayer) => {
        if(this.props.showChecks){
            return <Tooltip label={translate(item.checked ? "players.uncheck" : "players.check")}>
                <Button disabled={this.state.loading} color={item.checked ? "dark" : "green"} shape="text" onClick={() => this.toggleCheck(item)}>
                    {item.checked ? <FontAwesomeIcon icon={faSquareXmark} size="lg" /> : <FontAwesomeIcon icon={faSquareCheck} size="lg" />}
                </Button>
            </Tooltip>
        }
    }
}