import enContent from "../langs/en.json"
import itContent from "../langs/it.json"
import { CustomStorage } from "./CustomStorage"

export enum Lang{
    IT = "it",
    EN = "en"
}

export const getCurrentLanguage = () => CustomStorage.get(CustomStorage.STORAGE_LANG) === Lang.IT ? Lang.IT : Lang.EN

export const translate = (key: string, params?: Object): string => {
    const langContent = getCurrentLanguage() === Lang.IT ? itContent : enContent,
    base = key.split(".").reduce((prev, curr) => prev?.[curr], langContent as any),
    ret = base || key

    if(params)
        return Object.keys(params).reduce((curr, key) => curr.replace(`{${key}}`, params[key as keyof Object]), ret)

    return ret
}