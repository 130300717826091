export interface PlayersXML{
    readonly players: {
        readonly player: PlayerXML[]
    }
}

interface PlayerXML{
    readonly birthdate: string 
    readonly creationdate: string 
    readonly firstname: string 
    readonly lastmodifieddate: string 
    readonly lastname: string 
    readonly userid: string 
}

export const PlayersKeysNeeded: (keyof PlayerXML)[] = [
    "birthdate",
    "firstname",
    "lastname",
    "userid"
]