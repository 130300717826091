import _ from "lodash"
import React from "react"
import { IContext } from "../interfaces/IContext"

export interface ILoading{
    readonly loading: boolean
}

export type LogState<S> = Partial<S> & Pick<S, keyof unknown>

export abstract class CanLoad<P = unknown, S = unknown> extends React.Component<P, ILoading & S>{
    context: IContext

    constructor(props: P, state: S, loading = false){
        super(props)

        this.state = {
            ...state,
            loading
        }
    }
    
    protected toggleLoading = () => this.setInternalState({ loading: !this.state.loading }, "Toggled loading")

    protected setInternalState = (state: LogState<S | ILoading>, debug: string, callback?: () => void) => {
        if(process.env.NODE_ENV === "development")
            this.logState(state, debug)

        this.setState(state as S & ILoading, callback)
    }

    protected logState = (state: LogState<S | ILoading>, debug: string) => {
        const keys = Object.keys(state),
        date = new Date()

        console.log(
            `%c■ ${this.constructor.name} %c-%c ${debug} %c- [%c${date.toLocaleString()}%c]`,
            "color: #0efbfb", "color: white", "color: #ce510d", "color: white", "color: #1fdc63", "color: white",
            "\nOLD STATE", _.pick(this.state, keys),
            "\nNEW STATE", state
        )
    }
}