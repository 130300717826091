import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { ComponentDialogProps, Dialog, Form, FormButton, FormGroup, FormInput } from "dolfo2"
import { IBaseID } from "pkm-special-tool"
import React from "react"
import { makeApiCall, ServerCall, showMessage } from "../../apis/ServerCall"
import { CanLoad } from "../../utils/CanLoad"
import { translate } from "../../utils/LangUtils"
import { HTMLEditor } from "../HTMLEditor"


export class EmailWriter extends CanLoad<ComponentDialogProps & IBaseID>{
    form = new FormGroup({
        title: { value: "", required: true },
        content: { value: "", required: true }
    })

    submit = () => {
        this.toggleLoading()

        makeApiCall({
            promise: ServerCall.post("players/emails/" + this.props.id, this.form.getObjectValue()),
            onSuccess: () => {
                showMessage(translate("emailWrite.success"), faCheckCircle, "green")
                this.props.close()
            },
            doFinally: () => this.toggleLoading()
        })
    }
    
    render = () => <Dialog visible title={translate("emailWrite.title")} width={500} hideFooter className="top-dialog">
        <Form frm={this.form} onSubmit={this.submit}>
            <FormInput controlName="title" maxLength={200} label={translate("emailWrite.fieldTitle")} disabled={this.state.loading} />

            <HTMLEditor label={translate("emailWrite.fieldContent")} disabled={this.state.loading} onChange={val => this.form.set("content", val)} required />
            
            <div className="text-end mt-3">
                <FormButton color="blue2" loading={this.state.loading}>{translate("emailWrite.send")}</FormButton>
            </div>
        </Form>
    </Dialog>
}