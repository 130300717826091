export class CustomStorage{
    static readonly STORAGE_TOKEN = "token"
    static readonly STORAGE_LANG = "lang"

    static set = (key: string, value: string) => localStorage.setItem(key, value)
    
    static get = (key: string) => localStorage.getItem(key)

    static remove = (key: string) => localStorage.removeItem(key)

    static has = (key: string) => !!localStorage.getItem(key)
}