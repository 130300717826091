import { IApiUser, IGPlayer, ILogin } from "pkm-special-tool"
import { ServerCall } from "./ServerCall"

export class AuthService extends ServerCall{
    public static getSession = () => this.get<IApiUser | IGPlayer>("auth/session")

    public static login = (body: ILogin) => this.post<string>("auth/login", body)

    public static findGoogleUser = (email: string, google_id: string) => this.post<string>("auth/google-user", { email, google_id })

    public static registerWithGoogle = (body: Omit<IGPlayer, "id">) => this.post<string>("auth/register-with-google", body)
}