import { faBan, faCheckCircle, faChevronLeft, faPalette, faQrcode, faSync, faUserPlus, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Button, Chip, Dialog, Dropdown, FullColors, IColumn, Tab, Table, TableColumn, Tabs, Tooltip } from "dolfo2"
import _ from "lodash"
import moment from "moment"
import { InternalType, IPod, ISubInfo, ITournament, ITournamentApi, ITournamentStanding, RolesPermissionsType, SubscriptionType, timeLimitExceeded } from "pkm-special-tool"
import QRCode from "qrcode"
import React, { createRef } from "react"
import { makeApiCall, ServerCall, showMessage } from "../apis/ServerCall"
import { TournamentTickService } from "../apis/TournamentTickService"
import { IMatchPlayerStyle, IMatchRecord, IPairing } from "../interfaces/IPairing"
import { IStanding } from "../interfaces/IStanding"
import { EntityCRUD } from "../utils/EntityCRUD"
import { translate } from "../utils/LangUtils"
import { Router } from "../utils/Router"
import { TOURNAMENT_API_URL, TournamentsCRUD } from "./cruds/TournamentsCRUD"
import { SUBSCRIPTIONS_URL } from "./players/SubscribedPlayers"
import { TurnamentTick } from "./players/TurnamentTick"

enum PodCategoryType{
    MASTER = "Master",
    SENIOR = "Senior",
    JUNIOR = "Junior"
}

export class Home extends TournamentsCRUD{
    public apiURL = TOURNAMENT_API_URL + "?internal_type=" + (Router.getWindowUrl().startsWith(Router.LOCALS) ? InternalType.LOCALS : InternalType.REGIONALS)
    private pods: IPod[] = []
    private pairings: IPairing[] = []
    private tabsRef = createRef<Tabs>()
    private langObsIndex: number
    private logoutObsIndex: number
    private subInfo: ISubInfo

    // Reimposto i metodi astratti per non ereditarli dalla crud dei tornei
    protected canSave = () => false
    protected canDelete = () => false
    protected getActions = () => <></>
    public isAuthorized = () => true

    protected init = () => {
        this.langObsIndex = this.context.addLangObserver(this.checkTabs)

        this.logoutObsIndex = this.context.addLogoutObserver(this.checkTabs)

        return Promise.resolve()
    }

    componentDidUpdate = (_: typeof this.props, prevState: typeof this.state) => {
        if(!prevState.list && this.state.list && Router.getPathParams().id){
            const find = this.state.list.find(d => d.id === Router.getPathParams().id)

            if(find)
                this.openDetail(find)
        }else if(this.state.flag && !Router.getPathParams().id)
            this.closeDetail()
    }

    componentWillUnmount = () => {
        this.context.removeLangObserver(this.langObsIndex)
        this.context.removeLogoutObserver(this.logoutObsIndex)
    }

    private checkTabs = () => {
        if(this.tabsRef.current)
            this.tabsRef.current.handleBar()
    }

    public getColumns = () => {
        const cols = this.getBaseColumns().map((c, i) => {
            if(i === 0){
                return {
                    ...c,
                    extract: (d: ITournamentApi) => {
                        if(!d.type_custom_label)
                            return c.extract(d)
                        
                        return <>
                            <Chip className="me-2" color={this.context.getTournamentTypes().find(t => t.id === d.type).color as FullColors}>
                                {d.type_custom_label}
                            </Chip>

                            {d.name}
                        </>
                    }
                }    
            }

            return c
        })

        cols.splice(1, 0, {
            label: translate("tournaments.registered"),
            extract: d => `${d.playersCount}/${d.max_subscriptions ?? "inf"}`,
            width: 50,
            align: "right"
        })

        return cols
    }

    protected onOpenDetail = (item: ITournament) => {
        Router.navigate(`${(item.internal_type === InternalType.LOCALS ? Router.LOCALS : Router.REGIONALS)}/${item.id}`)
        this.toggleLoading()

        makeApiCall({
            promise: Promise.all([
                ServerCall.get<IPod[]>("pods/" + item.id),
                ServerCall.get<IPairing[]>("tournaments-pairings?id=" + item.id),
                this.hasPermission(RolesPermissionsType.SET_TICK) ? TournamentTickService.getTicks(item.id) : Promise.resolve([]),
                ServerCall.get<ISubInfo>(SUBSCRIPTIONS_URL + "/sub-info/" + item.id)
            ]),
            onSuccess: ([pods, pairings, ticks, subInfo]) => {
                this.pods = pods
                this.subInfo = subInfo
                this.setState({ ticks })

                this.pairings = _.orderBy(pairings, "round_number").map(p => ({
                    ...p,
                    matches: p.matches.map(m => ({
                        ...m,
                        ...this.getCellsColor(m)
                    }))
                }))
            },
            doFinally: this.toggleLoading
        })
    }

    protected closeDetail = () => {
        this.setFlag(null)
        Router.navigate(Router.getWindowUrl().startsWith(Router.LOCALS) ? Router.LOCALS : Router.REGIONALS)
    }

    private getCellsColor = (m: IPairing["matches"][0]): IMatchPlayerStyle => {
        switch(m.outcome){
            case "1": // win p1, loss p2
                return { p1: "green", p2: "red" }
            case "2": // loss p1, win p2
                return { p1: "red", p2: "green" }
            case "3": // draw
                return { p1: "blue2", p2: "blue2" }
            case "6": // in partita
                return { p1: "yellow", p2: "yellow" }
            case "10": // doppia loss
                return { p1: "red", p2: "red" }
            case "8": // in ritardo
                return {
                    p1: "white",
                    p2: "white",
                    p1Style: m.player1.late ? { fontStyle: "italic" } : null,
                    p2Style: m.player2?.late ? { fontStyle: "italic" } : null
                }
            case "4": // senza avversario
            case "5": // senza avversario
                return { p1: "green", p2: "white" }
            default:
                return { p1: "white", p2: "white" }
        }
    }

    private groupPairings = (pairings: IPairing[]) => pairings.reduce((a, b) => {
        const pod = this.pods.find(p => p.id === b.pod_id)

        if(a.has(pod.title))
            a.get(pod.title).push(b)
        else
            a.set(pod.title, [b])

        return a
    }, new Map<string, IPairing[]>())

    private getOrderedPairings = (pairings: IPairing[]) => _.orderBy(Array.from(this.groupPairings(pairings)), ([title]) => {
        if(title.indexOf("+") > 0){
            if(title.indexOf(PodCategoryType.JUNIOR) >= 0 && title.indexOf(PodCategoryType.SENIOR) >= 0)
                return 0
            if(title.indexOf(PodCategoryType.MASTER) >= 0 && title.indexOf(PodCategoryType.SENIOR) >= 0)
                return 1
            if(title.indexOf(PodCategoryType.JUNIOR) >= 0 && title.indexOf(PodCategoryType.MASTER) >= 0)
                return 2
        }

        if(title === PodCategoryType.JUNIOR)
            return 0
        if(title === PodCategoryType.SENIOR)
            return 1

        return 2
    })

    private groupRounds = () => _.groupBy(this.pairings, "round_number")

    private groupStandings = (p: ITournamentStanding[]) => _.groupBy(p, "pod_category")

    private extractWLT = (match_record: IStanding["match_record"] | IMatchRecord["matchRecord"]) => {
        let w: number, l: number, t: number

        if(match_record.hasOwnProperty("wins") && match_record.hasOwnProperty("losses") && match_record.hasOwnProperty("ties")){
            const cast = match_record as IStanding["match_record"]
            w = cast.wins
            l = cast.losses
            t = cast.ties
        }else{
            const cast = match_record as IMatchRecord["matchRecord"]
            w = cast.Wins
            l = cast.Losses
            t = cast.Ties
        }

        return `${w}/${l}/${t}`
    }

    private updateTick = (playerId: number) => TournamentTickService.setTicks(this.state.flag.id, playerId).then(() => {
        if(this.state.ticks.some(d => d.player_id === playerId))
            this.setState({ ticks: this.state.ticks.filter(d => d.player_id !== playerId) })
        else
            this.setState({ ticks: this.state.ticks.concat({ player_id: playerId, tournament_id: this.state.flag.id }) })
    })

    private calcolaPunteggi = (matchRecord: IStanding["match_record"]) => {
        const { prize_tix_per_win, prize_tix_for_participation } = this.state.flag,
        { wins, ties } = matchRecord

        return prize_tix_for_participation + (prize_tix_per_win * wins) + (prize_tix_per_win * ties / 2)
    }

    private viewQrCode = () => QRCode.toDataURL(window.location.href, (_err, url) => Dialog.open({
        title: translate("tournaments.qrCodeDialog"),
        children: <div className="text-center">
            <img src={url} alt="tournament-qr" />
        </div>,
        closeOnMask: true,
        hideFooter: true,
        width: "auto"
    }))

    private canSubscribe = () => this.subInfo && !this.subInfo.subscribed && this.subscriptionsOpened() && !timeLimitExceeded(this.state.flag.date, this.state.flag.start_time)

    private subscriptionsOpened = () => {
        const { subInfo, state, pairings } = this,
        { flag } = state

        return flag.subscription_type === SubscriptionType.GOOGLE_ACCOUNT && pairings.length === 0 && !subInfo.limit_exceeded
    }

    private isFull = () => this.subInfo?.limit_exceeded && this.pairings.length === 0

    private subscribe = () => Dialog.confirm(
        translate("warning"),
        translate("tournaments.confirmSubscribe"),
        () => {
            const dialog = EntityCRUD.openLoadingDialog()

            makeApiCall({
                promise: ServerCall.post(SUBSCRIPTIONS_URL + "/self", { tournament_id: this.state.flag.id }),
                onSuccess: () => {
                    showMessage(
                        translate("players.selfSubscribed"),
                        faCheckCircle,
                        "green"
                    )

                    this.onOpenDetail(this.state.flag)
                },
                onError: err => {
                    const { status } = err.response
    
                    if(status === 307){
                        Dialog.open({
                            title: <>
                                <FontAwesomeIcon icon={faWarning} className="fc-yellow" /> {translate("warning")}
                            </>,
                            hideFooter: true,
                            children: <span className="white-space-prewrap">{translate("players.alreadyExistsDiscrepancy")}</span>,
                            width: 400
                        })
                    }
    
                    return null
                },
                doFinally: dialog.close
            })

            return true
        }
    )

    private replaceUrls = (s: string) => {
        const regex = new RegExp(/((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/igm)

        return s.split(regex).map(d => {
            if(d.match(regex))
                return <a href={d} key={d} rel="noreferrer" target="_blank">{d}</a>

            return d
        })
    }

    protected getRowClass = (_: ITournamentApi, col: IColumn<ITournamentApi>): string => "white-space-prewrap"

    protected renderDetail = () => {
        const { flag, loading, ticks } = this.state,
        canViewStandings = this.hasPermission(RolesPermissionsType.VIEW_STANDINGS),
        canSetTicks = this.hasPermission(RolesPermissionsType.SET_TICK),
        showTabs = (this.pods.some(p => p.standings?.length > 0) && canViewStandings) || this.pairings.length > 0,
        rounds = this.groupRounds(),
        user = this.context.getUser()

        return <div className="pm-card bordered">
            <h4 className="d-flex align-items-start gap-1">
                <Tooltip label={translate("back")}>
                    <Button shape="pill" color="white" onClick={this.closeDetail}>
                        <FontAwesomeIcon icon={faChevronLeft} type="far" />
                    </Button>
                </Tooltip>
                <span>
                    {flag.name}
                    <small className="d-block" style={{ fontSize: 15 }}>
                        {translate("tournaments.organizer")}: <strong>{flag.organizer_name}</strong>
                    </small>
                    {
                        !!flag.start_time && <small className="d-block" style={{ fontSize: 15 }}>
                            {translate("tournaments.startTime")}: <strong>{moment(flag.start_time).format(translate("timeFormat"))}</strong>
                        </small>
                    }
                    {
                        !!flag.price && <small className="d-block" style={{ fontSize: 15 }}>
                            {translate("tournaments.price")}: <strong>{flag.price} &euro;</strong>
                        </small>
                    }
                </span>

                <Dropdown style={{ maxHeight: "unset" }} options={[
                    {
                        children: <div className="bg-red rounded px-2 py-1">
                            {translate("standings.legenda.loss")}
                        </div>
                    },
                    {
                        children: <div className="bg-green rounded px-2 py-1">
                            {translate("standings.legenda.win")}
                        </div>
                    },
                    {
                        children: <div className="bg-yellow rounded px-2 py-1">
                            {translate("standings.legenda.playing")}
                        </div>
                    },
                    {
                        children: <div className="bg-blue2 rounded px-2 py-1">
                            {translate("standings.legenda.draw")}
                        </div>
                    },
                    {
                        children: <em className="px-2 py-1 d-block">
                            {translate("standings.legenda.late")}
                        </em>
                    }
                ]}>
                    <Tooltip label={translate("standings.legenda.title")}>
                        <Button className="ms-auto" color="white" shape="pill">
                            <FontAwesomeIcon icon={faPalette} />
                        </Button>
                    </Tooltip>
                </Dropdown>

                {(this.isOrganizer() || this.hasPermission(RolesPermissionsType.VIEW_QR_CODE)) &&<Tooltip label={translate("tournaments.qrCode")}>
                    <Button className="ms-2" color="orange" onClick={this.viewQrCode} shape="pill">
                        <FontAwesomeIcon icon={faQrcode} />
                    </Button>
                </Tooltip>}

                <Tooltip label={translate("standings.refresh")}>
                    <Button className="ms-2" color="blue2" disabled={loading} onClick={() => this.onOpenDetail(flag)} shape="pill">
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </Tooltip>

                {
                    this.canSubscribe() && user && user.isGoogle && <Button className="ms-2" color="green" disabled={loading} onClick={this.subscribe} shape="pill">
                        <FontAwesomeIcon icon={faUserPlus} />
                        <span className="hide-mobile ms-2">
                            {translate("tournaments.subscribe")}
                        </span>
                    </Button>
                }

                {
                    this.isFull() && <Chip color="red" className="small px-3 ms-2">
                        <FontAwesomeIcon icon={faBan} />
                        <span className="hide-mobile ms-2">{translate("tournaments.isFull")}</span>
                    </Chip>
                }
            </h4>

            <div style={{ paddingLeft: 36 }}>
                <p className="small white-space-prewrap">
                    {this.replaceUrls(flag.description)}
                </p>

                {
                    this.subInfo?.subscribed && user && !loading && <Alert color="blue2" className={showTabs ? "mb-2" : undefined}>
                        {translate("tournaments.youAreSubscribed")}
                    </Alert>
                }

                {
                    !user && !loading && this.subscriptionsOpened() && <Alert color="blue2" className={showTabs ? "mb-2" : undefined}>
                        <span dangerouslySetInnerHTML={{
                            __html: translate("tournaments.notSubscribedMsg", { url: Router.LOGIN_URL })
                        }}></span>
                    </Alert>
                }

                {
                    loading ? EntityCRUD.centeredLoading() : showTabs ? <Tabs ref={this.tabsRef}>
                        {
                            Object.keys(rounds).reverse().map(roundId => <Tab label={`Round ${roundId}`} key={roundId}>
                                {
                                    this.getOrderedPairings(rounds[roundId]).map(([title, pairings], i) => <div className="pairing-table" key={title}>
                                        <h4 className={i > 0 ? "mt-2" : null}>{title}</h4>

                                        <Table<IPairing["matches"][0]> data={pairings[0].matches} key={title} rowClass={(p, cell) => {
                                            if(cell.label === translate("pairings.player1"))
                                                return "pairing-cell bg-" + p.p1
                                            if(cell.label === translate("pairings.player2"))
                                                return "pairing-cell bg-" + p.p2
                                        }} rowStyle={(p, cell) => {
                                            if(cell.label === translate("pairings.player1"))
                                                return p.p1Style
                                            if(cell.label === translate("pairings.player2"))
                                                return p.p2Style
                                        }}>
                                            <TableColumn<IPairing["matches"][0]> label={translate("pairings.player1")} align="center" width="40%">
                                                {
                                                    e => `${e.player1.firstName} ${e.player1.lastName} [${e.player1.category}]\n ${this.extractWLT(e.player1.matchRecord)}`
                                                }
                                            </TableColumn>
                                            <TableColumn<IPairing["matches"][0]> label={translate("pairings.table")} align="center" width="20%">
                                                {
                                                    e => e.match_table
                                                }
                                            </TableColumn>
                                            <TableColumn<IPairing["matches"][0]> label={translate("pairings.player2")} align="center" width="40%">
                                                {
                                                    e => e.player2 ? `${e.player2.firstName} ${e.player2.lastName} [${e.player2.category}]\n ${this.extractWLT(e.player2.matchRecord)}` : <></>
                                                }
                                            </TableColumn>
                                        </Table>
                                    </div>)
                                }
                            </Tab>)
                        }

                        {
                            this.pods.some(d => d.standings?.length > 0) && canViewStandings && <Tab label={translate("standings.title")}>
                                {
                                    this.pods.map(p => {
                                        const group = this.groupStandings(p.standings),
                                        keys = _.orderBy(Object.keys(group), k => {
                                            if(k === PodCategoryType.MASTER)
                                                return 0
                                            if(k === PodCategoryType.SENIOR)
                                                return 1

                                            return 2
                                        })

                                        return <React.Fragment key={p.id}>
                                            {
                                                keys.map((key, i) => <React.Fragment key={key}>
                                                    <h4 className={i > 0 ? "mt-2" : null}>{key}</h4>

                                                    <Table<IStanding> data={(group[key] as any as IStanding[]).map(s => ({
                                                        ...s,
                                                        playerID: s.player.id,
                                                        name: `${s.player.firstName} ${s.player.lastName}`
                                                    }))} pagination={25}>
                                                        <TableColumn<IStanding> label={translate("standings.colStanding")} align="right" width={80}>
                                                            {s => s.position}
                                                        </TableColumn>
                                                        {canSetTicks && <TableColumn<IStanding> label={translate("standings.colTick")} align="center" width={80}>
                                                            {s => <TurnamentTick checked={ticks.some(d => d.player_id === Number(s.player.id))} onChange={() => this.updateTick(Number(s.player.id))} />}
                                                        </TableColumn>}
                                                        <TableColumn<IStanding> label={translate("standings.colName")} width={150} filter={{
                                                            key: "name"
                                                        }}>
                                                            {(s: any) => `${s.name}${s.player.late ? "*" : ""}`}
                                                        </TableColumn>
                                                        <TableColumn<IStanding> label={translate("standings.colFlight")} align="right" width={150} filter={{
                                                            key: "playerID"
                                                        }}>
                                                            {(s: any) => s.playerID}
                                                        </TableColumn>
                                                        <TableColumn<IStanding> label={translate("standings.colDropRound")} align="right" width={100}>
                                                            {s => s.player.dropRound}
                                                        </TableColumn>
                                                        <TableColumn<IStanding> label={translate("standings.colMatchRecord")} align="center" width={130}>
                                                            {s => `${this.extractWLT(s.match_record)} (${s.match_record.points})`}
                                                        </TableColumn>
                                                        <TableColumn<IStanding> label={translate("standings.colPrizeTix")} align="right" width={100}>
                                                            {s => this.calcolaPunteggi(s.match_record)}
                                                        </TableColumn>
                                                    </Table>
                                                </React.Fragment>)
                                            }
                                        </React.Fragment>
                                    })
                                }

                                <Alert className="mt-3" color="blue2">* {translate("standings.latePlayer")}</Alert>
                            </Tab>
                        }
                    </Tabs> : <></>
                }
            </div>
        </div>
    }
}