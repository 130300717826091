import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox } from "dolfo2"
import React from "react"
import { makeApiCall } from "../../apis/ServerCall"
import { CanLoad } from "../../utils/CanLoad"

interface IProps{
    readonly checked: boolean
    readonly onChange: () => Promise<unknown>
}

export class TurnamentTick extends CanLoad<IProps>{
    changeCheck = () => {
        this.toggleLoading()

        makeApiCall({
            promise: this.props.onChange(),
            doFinally: this.toggleLoading
        })
    } 

    render = () => this.state.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <Checkbox checked={this.props.checked} onChange={this.changeCheck} />
}