import { IBaseID } from "pkm-special-tool"
import { ServerCall } from "./ServerCall"

export class BaseCrudAPI<T extends IBaseID<string | number>>{
    constructor(private baseAPIUrl: string){}

    public getList = () => ServerCall.get<T[]>(this.baseAPIUrl)

    public post = (item: T) => ServerCall.post<T>(this.baseAPIUrl, item)

    public put = (item: T) => ServerCall.put<T>(this.baseAPIUrl + "/" + item.id, item)

    public delete = (item: T) => ServerCall.delete<T>(this.baseAPIUrl + "/" + item.id)
}