import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Table, TableColumn } from "dolfo2"
import { IBaseID, ICheckPlayer, IPlayer } from "pkm-special-tool"
import React from "react"
import { ServerCall, makeApiCall, showMessage } from "../../apis/ServerCall"
import { translate } from "../../utils/LangUtils"
import { SearchInput } from "../../utils/SearchInput"
import { SUBSCRIPTIONS_URL, getPlayerColumns } from "./SubscribedPlayers"

export class PlayersSearch extends SearchInput<ICheckPlayer, IBaseID>{
    private selectedItem: IPlayer

    protected getSource = (filter: string) => ServerCall.get<ICheckPlayer[]>("players/filter?filter=" + filter)

    protected isDisabled = () => this.state.flag

    addPlayer = () => {
        if(!this.selectedItem)
            return showMessage(translate("players.selectAPlayer"))

        const { id } = this.props

        this.toggle()

        makeApiCall({
            promise: ServerCall.post(SUBSCRIPTIONS_URL, {
                tournament_id: id,
                player_id: this.selectedItem.id
            }),
            onSuccess: () => showMessage(translate("masterDetail.saved"), faCheckCircle, "green"),
            doFinally: this.toggle
        })
    }

    customRender = () => {
        const { list, loading, flag } = this.state

        return <>
            <Table<ICheckPlayer> onSelectionChange={s => this.selectedItem = s[0]} data={list} pagination={25} isSelectable={() => !flag}>
                {
                    getPlayerColumns().map(c => <TableColumn<ICheckPlayer> key={c.label} label={c.label} align={c.align}>
                        {c.extract}
                    </TableColumn>)
                }
            </Table>

            <div className="dolfo-control"></div>

            <div className="d-flex gap-2">
                <Button shape="pill" className="ms-auto" onClick={this.addPlayer} loading={flag} disabled={loading} color="green">
                    {translate("players.add")}
                </Button>
            </div>
        </>
    }
}