import { LoaderClass } from "./LoaderClass"

interface IState<F> {
    readonly flag: F
}

export abstract class Flaggable<P = unknown, F = boolean, S = unknown> extends LoaderClass<P, IState<F> & S>{
    constructor(props: P, state?: S, loading = false) {
        super(props, {
            ...state,
            flag: null
        }, loading)
    }

    protected toggle = (flag = !this.state.flag) => this.setInternalState({ flag } as IState<F>, "Changed flag")

    protected setFlag = (flag: F) => this.setInternalState({ flag } as IState<F>, "Changed flag")
}