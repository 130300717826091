import { faCheckCircle, faClockRotateLeft, faTrashArrowUp, faUserShield } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, Tooltip } from "dolfo2"
import moment from "moment"
import { IApiGPlayer, IApiUser, ICheckPlayer, IPlayer, RolesPermissionsType } from "pkm-special-tool"
import React from "react"
import { ServerCall, makeApiCall, showMessage } from "../../apis/ServerCall"
import { EntityCRUD, SaveType } from "../../utils/EntityCRUD"
import { translate } from "../../utils/LangUtils"
import { AssignPermissionsDialog } from "../AssignPermissionsDialog"
import { PlayersForm } from "../players/PlayersForm"
import { getPlayerColumns } from "../players/SubscribedPlayers"

export class PlayersCRUD extends PlayersForm{
    public apiURL = "players"
    protected onlyInsertForm = false

    public isAuthorized = (user?: IApiUser | IApiGPlayer) => user?.permissions?.[RolesPermissionsType.DELETE_PLAYERS]

    protected canSave = (saveType: SaveType) => saveType !== SaveType.CREATE

    public getColumns = getPlayerColumns

    public getData = (items: ICheckPlayer[]) => items?.map(i => ({
        ...i,
        birthDate: new Date(i.birthDate)
    }))

    protected getToolbarActions = () => {
        const { loading } = this.state

        if(!this.isNew() && this.hasPermission(RolesPermissionsType.ASSIGN_PERMISSIONS)){
            return React.createElement(Tooltip, {
                label: translate("players.assignPermissions"),
                children: React.createElement(Button, {
                    color: "blue2",
                    shape: "text",
                    disabled: loading,
                    onClick: this.openPermissionsDialog,
                    children: React.createElement(FontAwesomeIcon, {
                        icon: faUserShield,
                        fixedWidth: true,
                        className: "h3"
                    })
                })
            })
        }
        
        return React.createElement(React.Fragment)
    }

    private openPermissionsDialog = () => Dialog.openComponent(AssignPermissionsDialog, { id: this.state.flag.id })

    protected getGridToolbarActions = () => React.createElement(Tooltip, {
        label: translate("players.deleteAll"),
        children: React.createElement(Button, {
            shape: "pill",
            color: "red",
            disabled: this.state.loading || this.state.list.length === 0,
            onClick: this.deleteAll,
            children: React.createElement(FontAwesomeIcon, { icon: faTrashArrowUp })
        })
    })

    private deleteAll = () => Dialog.confirm(
        translate("warning"),
        translate("players.deleteAllWarning"),
        () => {
            const loading = EntityCRUD.openLoadingDialog()
            
            makeApiCall({
                promise: ServerCall.delete(this.apiURL + "/empty"),
                onSuccess: () => {
                    showMessage(translate("players.deletedAll"), faCheckCircle, "green")
                    this.setInternalState({ list: [] }, "Players erased")
                },
                doFinally: loading.close
            })
            
            return true
        }
    )

    protected canDelete = () => this.hasPermission(RolesPermissionsType.DELETE_PLAYERS)

    protected getActions = (item: IPlayer) => {
        const { loading } = this.state

        return React.createElement(Tooltip, {
            label: translate("players.viewDates"),
            children: React.createElement(Button, {
                shape: "text",
                color: "green",
                disabled: loading,
                children: React.createElement(FontAwesomeIcon, {
                    icon: faClockRotateLeft
                }),
                onClick: () => this.showDates(item)
            })
        })
    }

    private showDates = (item: IPlayer) => Dialog.open({
        title: translate("players.dates.title"),
        children: React.createElement("div", {
            className: "d-flex flex-column gap-3",
            children: [
                React.createElement("span", {
                    key: "creation-date",
                    children: [
                        React.createElement("strong", {
                            children: translate("players.dates.creation"),
                            key: "strong"
                        }),
                        React.createElement("span", {
                            children: ": " + moment(new Date(item.creationDate)).format(translate("dateTimeFormat")),
                            key: "date"
                        })
                    ]
                }),
                React.createElement("span", {
                    key: "edit-date",
                    children: [
                        React.createElement("strong", {
                            children: translate("players.dates.edit"),
                            key: "strong"
                        }),
                        React.createElement("span", {
                            children: ": " + (item.editDate ? moment(new Date(item.editDate)).format(translate("dateTimeFormat")): ""),
                            key: "date"
                        })
                    ]
                })
            ]
        }),
        hideFooter: true,
        closeOnMask: true,
        width: 300
    })
}