import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faAlignCenter, faAlignLeft, faAlignRight, faBold, faItalic, faLink, faListOl, faListUl, faUnderline } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BaseInput, Formable, FormInternalInput, IDisabled, IRequired, OnChangeValue } from "dolfo2"
import React from "react"
import ReactDOM from "react-dom"
import { createButton, createDropdown, Editor, EditorProvider, EditorState, Separator, Toolbar } from "react-simple-wysiwyg"
import { translate } from "../utils/LangUtils"

export interface HTMLEditorProps extends OnChangeValue<string>, IDisabled, IRequired{
    readonly value?: string
    readonly onSendAction?: () => void
}

const btn = (key: string, icon: IconProp, cmd: ((state: EditorState) => void) | string) => createButton(translate("editor." + key), <FontAwesomeIcon icon={icon} />, cmd)

const BtnAlignCenter = () => btn("alignCenter", faAlignCenter, "justifyCenter"),
BtnAlignLeft = () => btn("alignLeft", faAlignLeft, "justifyLeft"),
BtnAlignRight = () => btn("alignRight", faAlignRight, "justifyRight"),
BtnNumberedList = () => btn("oList", faListOl, "insertOrderedList"),
BtnBulletList = () => btn("uList", faListUl, "insertUnorderedList"),
BtnBold = () => btn("bold", faBold, "bold"),
BtnItalic = () => btn("italic", faItalic, "italic"),
BtnUnderline = () => btn("underline", faUnderline, "underline"),
BtnLink = () => btn("link", faLink, ({ $selection }) => {
    if($selection?.nodeName === "A") 
        document.execCommand("unlink")
    else
        document.execCommand("createLink", false, prompt("URL", "") || undefined)
}),
BtnStyles = () => createDropdown(translate("editor.styles.label"), [
    [translate("editor.styles.normal"), "formatBlock", "DIV"],
    [translate("editor.styles.title"), "formatBlock", "H1"],
    [translate("editor.styles.subtitle"), "formatBlock", "H2"],
    [translate("editor.styles.code"), "formatBlock", "PRE"]
])

export class HTMLEditor extends BaseInput<string, HTMLInputElement, HTMLEditorProps, { value: string, polishedText: string }>{
    constructor(props: HTMLEditorProps){
        const div = document.createElement("div")
        div.innerHTML = props.value || ""
        
        super(props, {
            value: props.value || "",
            polishedText: div.innerText
        })
    }

    componentDidUpdate = (prevProps: HTMLEditorProps) => {
        if(prevProps.disabled !== this.props.disabled){
            const node = ReactDOM.findDOMNode(this) as HTMLElement,
            editor = node.querySelector(".rsw-editor")

            editor.classList.toggle("disabled", this.props.disabled)
        }
    }

    render = () => {
        const { disabled, onChange, required, onSendAction } = this.props,
        { value, polishedText } = this.state,
        Bold = BtnBold(),
        Italic = BtnItalic(),
        Underline = BtnUnderline(),
        AlignLeft = BtnAlignLeft(),
        AlignCenter = BtnAlignCenter(),
        AlignRight = BtnAlignRight(),
        BulletList = BtnBulletList(),
        NumberedList = BtnNumberedList(),
        Link = BtnLink(),
        Styles = BtnStyles()

        return <div className="editor-container">
            {this.getLabel()}
            <EditorProvider>
                <Editor value={value} disabled={disabled} onChange={e => this.setState({
                    value: e.target.value,
                    polishedText: (e.nativeEvent.target as HTMLElement).innerText
                }, () => onChange(e.target.value, e as any))} onKeyDown={e => {
                    if(e.key === "Enter" && e.ctrlKey && onSendAction)
                        onSendAction()
                }}>
                    <Toolbar>
                        <Bold />
                        <Italic />
                        <Underline />
                        <Separator />
                        <AlignLeft />
                        <AlignCenter />
                        <AlignRight />
                        <Separator />
                        <BulletList />
                        <NumberedList />
                        <Separator />
                        <Link />
                        <Separator />
                        <Styles />
                        <Separator />
                    </Toolbar>
                </Editor>
            </EditorProvider>

            <input type="text" ref={r => this.input = r} value={polishedText} required={required} onChange={() => {}} />
        </div>
    }
}

@Formable(HTMLEditor)
export class FormHTMLEditor extends FormInternalInput<HTMLEditorProps, string>{}